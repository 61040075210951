import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    maxWidth: 600,
    minHeight: 300,
    maxHeight: 700,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  scroll: {
    overflow: "auto",
    maxHeight: 600,
    backgroundColor: "#ddd",
    padding: 8
  }
}));

function JsonWindow({ handleCopy, json }) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button onClick={handleOpen} color="secondary" variant="contained">
        GENERATE JSON
      </Button>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div style={modalStyle} className={classes.paper}>
          <div>
            <pre className={classes.scroll}>{json}</pre>
          </div>
          <Box display="flex" justifyContent="space-between">
            <Button
              onClick={handleCopy}
              color="primary"
              variant="contained"
            >
              COPY JSON
          </Button>
            <Button
              onClick={handleClose}
              color="secondary"
              variant="contained"
            >
              CLOSE
          </Button>
          </Box>
        </div>
      </Modal>
    </div>
  );
}
export default JsonWindow;
