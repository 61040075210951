import React from "react";
import { observer } from "mobx-react";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";

const SelectAccount = observer(props => {

  const {
    accounts,
    onChange,
    value
  } = props;

  return (
    <FormControl style={props.style}>
      <InputLabel id="lbl-input-editor-select-account">Account</InputLabel>
      <Select
        labelId="lbl-input-editor-select-account"
        id="ddl-input-editor-select-account"
        value={value}
        onChange={e => { onChange(e.target.value) }}
      >
        {accounts.map(account => {
          return (
            <MenuItem key={account.number} value={account.number}>{account.name}</MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
});

export default SelectAccount;