import React from "react";
import SignIn from "../../components/SignIn";

import {Typography} from "@material-ui/core";

export const HomeScreen = () => {
  return (
    <div>
      <Typography variant="h1">Home</Typography>
      <SignIn></SignIn>
    </div>
  );
};
