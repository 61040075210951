import React from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";

import { Link } from "react-router-dom";

import { viewStore } from "../../store/signInViewStore";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginTop: theme.spacing(1)
  },
  userName: {
    lineHeight: "48px",
    textAlign: "center",
    paddingLeft: theme.spacing(2)
  }
}));

export default observer(() => {
  const classes = useStyles();
  let currentPath = window.location.pathname;
  if (currentPath.startsWith("/help")) {
    currentPath = "/help/";
  }

  const [value, setValue] = React.useState(currentPath);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log(viewStore)

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab
                id="navHome"
                label="Home"
                value={"/"}
                component={Link}
                to={"/"} />
              <Tab
                id="navDocumentation"
                label="Documentation"
                value={"/documentation"}
                component={Link}
                disabled={!viewStore.isLoggedIn}
                to={viewStore.documentationUrl}
              />
              <Tab
                id="navBuildModel"
                label="Build Model"
                value={"/build_model"}
                disabled={!viewStore.isLoggedIn}
                component={Link}
                to={viewStore.buildModelUrl}
              />
              <Tab
                id="navHelp"
                label="Help"
                value="/help/"
                disabled={!viewStore.isLoggedIn}
                component={Link}
                to="/help/index"
              />
            </Tabs>
          </Box>
          <Box display="flex">
            {viewStore.isLoggedIn &&
              viewStore.userData &&
              viewStore.userData.photoURL && (
                <Avatar
                  className={classes.avatar}
                  alt={viewStore.userData.name}
                  src={viewStore.userData.photoURL} />
              )}
            {viewStore.isLoggedIn &&
              viewStore.userData &&
              viewStore.userData.name && (
              <Typography className={classes.userName}>{viewStore.userData.name}</Typography>
              )}
            {viewStore.isLoggedIn ? (
              <Tab
                id="navLogout"
                label="Logout"
                value={"/logout"}
                component={Link}
                to={"/"}
                onClick={() => viewStore.logout()}
              />
            ) : null}
          </Box>
        </Box>
      </AppBar>
    </div >
  );
});
