export function parseOutput(output) {
  const result = {
    name: output[0],
    value: output[1],
    type: "store",
    options: {}
  }

  if (output.length >= 3) {
    result.type = output[2];
  }
  if (output.length >= 4) {
    result.options = output[3];
  }

  if (result.type === "literal" && !isNaN(result.value)) {
    result.value = Number(result.value);
  }

  return result;
}