import React from "react";
import { observer } from "mobx-react";
import ReactMarkdown from "react-markdown";

export const MarkdownDisplay = observer((props) => {
  const {
    content
  } = props;

  return (
    <div className="custom-html-style">
      <ReactMarkdown>{content}</ReactMarkdown> 
    </div>
  );
});
