import React from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Zoom,
  Button
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";

const useStyles = makeStyles(theme => ({
  list: {
    width: "100%",
    overflow: "auto"
  },
  variable: {
    fontFamily: "monospace",
    margin: 0
  }
}));

export default observer(props => {
  const { variables } = props;
  const classes = useStyles();

  return (
    <List className={classes.list}>
      {variables.map((variable, index) => {
        return (
          <ListItem key={variable.name}>
            <ListItemIcon>
              <Button
                onClick={() => navigator.clipboard.writeText(variable.name)}
              >
                <FileCopyIcon />
              </Button>
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ component: "pre" }}>
              <Tooltip
                title={`Source: ${variable.source}`}
                placement="left"
                TransitionComponent={Zoom}
                arrow
              >
                <pre className={classes.variable}>
                  {index + 1}. {variable.name}
                </pre>
              </Tooltip>
            </ListItemText>
          </ListItem>
        );
      })}
    </List>
  );
});
