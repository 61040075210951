import React from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Color from 'color';
import { viewStore as userContext } from "../../../store/signInViewStore";
import HelpContentsViewStore from "./viewStore";

import {
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Modal,
  Snackbar,
  TextField,
  Typography
} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  drawer: {
    flexShrink: 0,
    width: drawerWidth

  },
  drawerPaper: {
    top: 40,
    width: drawerWidth
  },
  pageLink: {
    "&:hover": {
      backgroundColor: Color(theme.palette.info.light).alpha(0.25).string()
    }
  },
  linkText: {
    "&:visited": {
      color: theme.palette.primary.main
    }
  },
  modalBox: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    width: 400,
    maxWidth: "90%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

const viewStore = new HelpContentsViewStore();

export default observer((props) => {
  const classes = useStyles();

  const {
    pages,
    selectedPage
  } = props;

  const onSubmit = (event) => {
    viewStore.saveNewPage();
    event.preventDefault();
  }

  return (
    <>
      <Drawer
        id="help-table-of-contents"
        className={classes.drawer}
        classes={{ paper: classes.drawerPaper }}
        variant="permanent"
        anchor="left"
      >
        <List>
          <ListItem key={"Table of Contents"}>
            <ListItemText>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h5">Help Pages</Typography>
                {userContext.canEditPages && (
                  <Button color="primary" onClick={viewStore.createNewPage}>+ Add</Button>)}
              </Box>
            </ListItemText>
          </ListItem>
          {pages.map(page => (
            <ListItem
              key={page.id}
              selected={page.id === selectedPage}
              className={classes.pageLink}>
              <ListItemText>
                <Link to={`/help/${page.id}`} className={classes.linkText}>{page.name}</Link>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Modal open={viewStore.modal.isOpen} onClose={viewStore.modal.close}>
        <form onSubmit={onSubmit}>
          <Box
            className={classes.modalBox}
            display="flex"
            flexDirection="column"
          >
            <Typography variant="h5" style={{ marginBottom: 16 }}>Create new help page</Typography>

            <TextField
              id="txt-new-help-page-title"
              label="Page Title"
              value={viewStore.title}
              onChange={(event) => viewStore.setTitle(event.target.value)}
            />

            <TextField
              id="txt-new-help-page-key"
              label="Page Key"
              value={viewStore.pageKey}
              style={{ marginTop: 8 }}
              disabled />

            <Box
              style={{ marginTop: 20 }}
              display="flex"
              justifyContent="space-between"
            >
              <Button
                id="btn-perform-save-new-help-page"
                color="primary"
                variant="contained"
                type="submit"
                onClick={onSubmit}>
                Save
            </Button>
              <Button
                id="btn-cancel-save-new-help-page"
                onClick={viewStore.modal.close}
                color="secondary"
                variant="contained"
              >
                Cancel
            </Button>
            </Box>
          </Box>
        </form>
      </Modal>

      <Snackbar
        open={viewStore.showSuccess}
        autoHideDuration={6000}
        onClose={viewStore.clearSuccess}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity="success"
          onClose={viewStore.clearSuccess}
        >New page created</MuiAlert>
      </Snackbar>
      <Snackbar
        open={viewStore.showError}
        autoHideDuration={6000}
        onClose={viewStore.clearError}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity="error"
          onClose={viewStore.clearError}
        >{viewStore.errorMessage}</MuiAlert>
      </Snackbar>
    </>
  );
});
