import { createMuiTheme } from '@material-ui/core/styles';

import {grey, red, green, orange, blue} from '@material-ui/core/colors';

const theme1 = {
    
    //type:"dark",

    palette: {
        primary: {
            main: "#333A4A",
            //dark: "#232A3A",
            //light: "#434A5A",
            //contrastText:"#FFF",
        },

        secondary: {
            main: "#F78E1E",
            dark: "#E77E0E",
            light: "#FF9E2E",
            contrastText:"#FFF",
        },

        tertiary : {
            main: "#FFD200",
            dark: "#DFB200",
            light: "#FFE311",
            contrastText:"#000000",
        },

        text: {
            //primary: "#231F20",
        },

        divider: grey[200],
        
        background: {
            // paper: "#ffffff",
            // default: "#fafafa",
        },

        error: {
            main: red[500],
        },
        success: {
            main: green[500],
        },
        warning: {
            main: orange[500],
        },
        info : {
            main: blue[500],
        },


        action: {
            // active: "#000000",
            // //hover: "#000000",
            // hoverOpacity: 1,
            // selected: "#000000",
            // selectedOpacity: 1,
            //disabled: "#ff0000",
            //disabledBackground: "#ff0000",
            disabledOpacity: 0,
            // focus: "#000000",
            // focusOpacity: 1,
            // activatedOpacity: 1
          },
    },

    themeBackground: "url('https://img5.goodfon.com/wallpaper/nbig/7/64/abstract-background-rounded-shapes-colorful-abstraktsiia-tek.jpg')",
    
    typography: {
        //fontFamily: "Lexend Deca",
        //fontFmily: "Montserrat Alternates",
        fontFamily: 'futura-pt',
        
        body1: {
            fontSize: 18,
        },
        body2: {
            fontSize: 14,
        },
        caption: {
            fontSize: 14,
        },
        h1: {
            fontSize: 36,
            textTransform: "uppercase",
            fontWeight: "bold",
            letterSpacing: 2,
            lineHeight: 'reset'
          },
          h2: {
            fontSize: 24,
            textTransform: "uppercase",
            fontWeight: "bold",
            letterSpacing: 2,
          },
          h3: {
            fontSize: 18,
            textTransform: "uppercase",
            fontWeight: "bold",
            letterSpacing: 2,
          },
          h4: {
            fontSize: 14,
            textTransform: "uppercase",
            fontWeight: "bold",
            letterSpacing: 2,
          },
          h5: {
              fontSize: 12,
              textTransform: "uppercase",
              fontWeight: "bold",
              letterSpacing: 2,
          },
          h6: {
              fontSize: 10,
              textTransform: "uppercase",
              fontWeight: "bold",
              letterSpacing: 2,
          },
          gutterBottom: {
            marginBottom: 32,
          },
    },

    spacing: 8,

    shape: {
        borderRadius: 4,
    },

    transitions: {
        duration: {
            //complex: 1000,
            enteringScreen: 500,
            leavingScreen: 500,
            //short: 250,
            //shorter: 200,
            //shortest: 150,
            //standard: 300,
        },
      },

    overrides: {
        MuiButton:{
            label: {
                fontSize: 18,
            },
            text:{
                textTransform: "none",
            },
            contained:{
                textTransform: "none",
            },
            outlined:{
                textTransform: "none",
            },
            containedSecondary:{
                // root:{
                //     backgroundImage: `linear-gradient(45deg, #F78E1E 0%, #FF9E2E 100%)`,
                //     "&:disabled": {
                //         backgroundImage: "none",
                //     },
                // }
            },
            containedPrimary:{
                // backgroundImage: `linear-gradient(45deg, #232A3A 0%, #434A5A 100%)`,
                // "&:disabled": {
                //     backgroundImage: "none",
                // },
            },
        },
        MuiTableCell: {
            root: {
                borderBottom: "1px solid " + grey[200],
            }
        },
        MuiSelect: {
            root:{
            }
        },
        MuiInput: {
            fontSize: 18,
            root: {
                fontSize: 18,
            }
        },
        MuiStepIcon:{
            root:{
            },
            completed: {
                color: green[500] +"!important",
            },
            active: {
                color: orange[500] +"!important",
            }
        },
        MuiTab: {
            wrapper: {
                fontSize: 18,
                textTransform: "none",
            }
        },
        MuiTooltip:{
            tooltip: {
                fontSize: 18,
            }
        },
        MuiAlert: {
            root:{
            fontSize: 18,
            }
        },
        MuiLinearProgress: {
            root: {
            height: "8px",
            },
        },
        MuiChip: {
            label:{
                fontSize: 18,
            }
        }
    },
    props: {
        MuiTabs: {
            indicatorColor: "primary",
        },
        MuiTextField: {
            variant:"outlined",
            size: "small",
        },
        MuiButton: {
            //variant: "contained",
            //color: "primary"
        },
        MuiSelect: {
            margin: "dense",
        }
    }
}

export default createMuiTheme(theme1)
export {theme1 as rawTheme }