import React from "react";
import MaterialTable from "material-table";

import {observer} from "mobx-react";

const InitialValues = observer(props => {
  const viewStore = props.initialValuesStore;

  return (
    <div>
      <div>
        <MaterialTable
          title="Initial Values"
          columns={viewStore.columns}
          data={viewStore.valueData}
          editable={{
            onRowAdd: newData => viewStore.addInitialValue(newData.variable, newData.value),
            onRowUpdate: (newData, oldData) => viewStore.editInitialValue(oldData.variable, newData.variable, newData.value),
            onRowDelete: oldData => viewStore.deleteInitialValue(oldData.variable)
          }}
          options={{
            paging: false,
            pageSizeOptions: []
          }}
          />
      </div>
    </div>
  );
});

export default InitialValues;
