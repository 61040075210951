import React from "react";

import { Button } from "@material-ui/core";

const SaveButtons = ({ save }) => {
  return (
    <>
      <Button color="secondary" variant="contained" onClick={() => save()}>
        SAVE
      </Button>
    </>
  );
};

export default SaveButtons;
