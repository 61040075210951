import { observable, action, decorate, computed } from "mobx";
import { createTransformer } from "mobx-utils";

class VariablePickerViewStore {
  product = "";
  region = "";
  round = "";
  variable = "";
  startingValue = "";
  isDirty = false;

  get coreValue() {
    return this.variable || this.startingValue;
  }


  processRegionTemplate(result, region) {
    const replaceValue = region || "{Reg}";
    const template = /\{Reg\}/g;
    if (template.test(result)) {
      result = result.replace(template, replaceValue);
    }
    else {
      const pattern = /Reg\d+/g;
      if (pattern.test(result)) {
        result = result.replace(pattern, `Reg${replaceValue}`);
      }
    }
    return result;
  }

  processProductTemplate(result, product) {
    const replaceValue = product || "{Prod}";
    const prodTemplate = /\{Prod\}/g;
    if (prodTemplate.test(result)) {
      result = result.replace(prodTemplate, replaceValue);
    }
    else {
      const prodPattern = /Prod\d+/g;
      if (prodPattern.test(result)) {
        result = result.replace(prodPattern, `Prod${replaceValue}`);
      }
    }
    return result;
  }

  processRoundTemplate(result, round) {
    const replaceValue = round !== "" ? round : "{Round}";
    const template = /\{Round\}/g;
    const templateWithAdjustment = /\{Round[+|-]\d+\}/g;
    const pattern1 = /Rd\d+/g;
    const pattern2 = /:\d+$/;

    if (template.test(result)) {
      result = result.replace(template, replaceValue);
    } else if(templateWithAdjustment.test(result) && round !== "") {
      result = result.replace(templateWithAdjustment, replaceValue);
    } else {
      result = result.replace(pattern1, `Rd${replaceValue}`).replace(pattern2, `:${replaceValue}`);
    }

    return result;
  }

  get outputValue() {
    let result = this.coreValue;

    if (this.hasProductTemplate) {
      result = this.processProductTemplate(result, this.product);
    }

    if (this.hasRegionTemplate) {
      result = this.processRegionTemplate(result, this.region);
    }

    if (this.hasRoundTemplate) {
      result = this.processRoundTemplate(result, this.round);
    }

    return result;
  }

  get hasProductTemplate() {
    const pattern = /(\{Prod\})|(Prod\d+)/;

    return pattern.test(this.coreValue);
  }

  get hasRegionTemplate() {
    const pattern = /(\{Reg\})|(Reg\d+)/;

    return pattern.test(this.coreValue);
  }

  get hasRoundTemplate() {
    const pattern = /(\{Round\})|(Rd\d+)|(:\d+$)|(\{Round[+|-]\d+\})/;

    return pattern.test(this.coreValue);
  }

  get productSelection() {
    return this.hasProductTemplate ? this.product : "";
  }

  get regionSelection() {
    return this.hasRegionTemplate ? this.region : "";
  }

  get roundSelection() {
    return this.hasRoundTemplate ? this.round : "";
  }

  roundList = createTransformer(rounds => {
    const list = [];

    for (var i = 0; i < rounds + 1; i++) {
      list.push(String(i));
    }
    for (i = rounds + 1; i > 0; i--) {
      list.push(`{Round-${i}}`);
    }
    for (i = 1; i < rounds + 2; i++) {
      list.push(`{Round+${i}}`);
    }

    return list;
  });

  initValues() {
    if (this.hasProductTemplate) {
      const pattern = /Prod(\d+)/;
      const match = this.coreValue.match(pattern);
      if (match && match.length >= 2) {
        this.product = String(Number(match[1]));
      }
    }
    if (this.hasRegionTemplate) {
      const pattern = /Reg(\d+)/;
      const match = this.coreValue.match(pattern);
      if (match && match.length >= 2) {
        this.region = String(Number(match[1]));
      }
    }
    if (this.hasRoundTemplate) {
      const pattern1 = /:(\d+)$/;
      const pattern2 = /Rd(\d+)/;
      const match1 = this.coreValue.match(pattern1);
      if (match1 && match1.length >= 2) {
        this.round = String(Number(match1[1]));
      }

      const match2 = this.coreValue.match(pattern2);
      if (match2 && match2.length >= 2) {
        this.round = String(Number(match2[1]));
      }
    }
  }

  setValue(key, value) {
    this[key] = value;

    if ((key === "variable" || key === "startingValue") && !this.isDirty) {
      this.isDirty = false;
      this.initValues();
    } else {
      this.isDirty = true;
    }
  }

  reset() {
    this.product = "";
    this.region = "";
    this.round = "";
    this.variable = "";
    this.startingValue = "";
    this.isDirty = false;
  }
}

decorate(VariablePickerViewStore, {
  product: observable,
  region: observable,
  round: observable,
  variable: observable,
  isDirty: observable,
  coreValue: computed,
  outputValue: computed,
  hasProductTemplate: computed,
  hasRegionTemplate: computed,
  hasRoundTemplate: computed,
  productSelection: computed,
  regionSelection: computed,
  roundSelection: computed,
  initValues: action.bound,
  setValue: action.bound,
  reset: action.bound
});

export default VariablePickerViewStore;