import React from "react";
import { observer } from "mobx-react";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Modal, TextField, Typography } from "@material-ui/core";
import ModalGenericStore from "../../store/modalGenericStore";

const modalStore = new ModalGenericStore();

const useStyles = makeStyles(theme => ({
  modalBox: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    width: "90%",
    maxWidth: 900,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  scroll: {
    overflow: "auto"
  },
  buttonRow: {
    marginTop: "20px"
  },
  importField: {
    width: "100%",
    maxWidth: 900
  }
}));

const JsonImporter = observer(props => {
  const classes = useStyles();
  const { viewStore } = props;

  const onChange = event => {
    viewStore.jsonToImport = event.target.value;
  };

  const importClicked = () => {
    viewStore.importJson();
    modalStore.close();
  };

  return (
    <div>
      <Button color="primary" variant="contained" onClick={modalStore.open} id="btn-import-json">
        Import JSON
      </Button>
      <Modal open={modalStore.isOpen} onClose={modalStore.close}>
        <Box
          className={classes.modalBox}
          display="flex"
          flexDirection="column"
        >
          <Typography variant="h5">Paste JSON to Import</Typography>

          <Box className={classes.scroll}>
            <TextField
              variant="filled"
              rows="25"
              multiline
              className={classes.importField}
              placeholder="JSON goes here..."
              onChange={onChange}
            />
          </Box>
          <Box
            className={classes.buttonRow}
            display="flex"
            justifyContent="space-between"
          >
            <Button 
              id="btn-perform-json-import"
              color="primary" 
              variant="contained" 
              onClick={importClicked}>
              Import
            </Button>
            <Button
              id="btn-close-json-import-window"
              onClick={modalStore.close}
              color="secondary"
              variant="contained"
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
});

export default JsonImporter;
