import { observable, action, decorate, computed } from "mobx";

class ChartOfAccountsStore {
  accounts = [];

  addAccount(newAccount) {
    const promise = new Promise((resolve, reject) => {
      const existing = this.accounts.find(account => {
        return account[0] === newAccount.id || account[1] === newAccount.name;
      });
      if (existing) {
        reject("Account already exists");
        return;
      }

      const parent = newAccount.parent === "null" ? null : newAccount.parent;
      this.accounts.push([newAccount.id, newAccount.name, newAccount.category, parent]);
      resolve();
    });

    return promise;
  }

  editAccount(oldAccount, newAccount) {
    const promise = new Promise((resolve, reject) => {
      const existing = this.accounts.find(account => account[0] === oldAccount.id);

      if (!existing) {
        reject();
        return;
      }

      existing[0] = newAccount.id;
      existing[1] = newAccount.name;
      existing[2] = newAccount.category;
      existing[3] = newAccount.parent === "null" ? null : newAccount.parent;

      const children = this.accounts.filter(account => account[3] === oldAccount.id);
      children.forEach(account => {
        account[3] = newAccount.id;
      });
      resolve();
    });

    return promise;
  }

  deleteAccount(oldAccount) {
    const promise = new Promise((resolve, reject) => {
      const index = this.accounts.findIndex(account => account[0] === oldAccount.id);
      const children = this.accounts.filter(account => account[3] === oldAccount.id);

      // must exist and have no child accounts
      if (index < 0 || children.length > 0) {
        reject();
        return;
      }

      this.accounts.splice(index, 1);
      resolve();
    });

    return promise;
  }

  loadAccounts(accounts) {
    this.accounts.clear();

    accounts.forEach(account => {
      if (account[2] && account[2].includes("Expense")) {
        account[2] = "Expense";
      }
      this.accounts.push(account);
    });
  }

  get accountLookup() {
    return this.accounts.map(account => {
      return {
        number: account[0],
        name: `${account[0]}: ${account[1]}`
      }
    });
  }

  get parents() {
    const results = { null: "-- No Parent --" };
    this.accounts.forEach(account => {
      results[account[0]] = `${account[0]}: ${account[1]}`;
    });

    return results;
  }

  get accountTypes() {
    return {
      "Asset": "Asset",
      "Equity": "Equity",
      "Expense": "Expense",
      "Liability": "Liability",
      "Revenue": "Revenue"
    };
  }

  get columns() {
    return [
      { title: "Account Number", field: "id" },
      { title: "Name", field: "name" },
      { 
        title: "Account Type", 
        field: "category",
        lookup: this.accountTypes
      },
      { 
        title: "Parent Account", 
        field: "parent",
        lookup: this.parents,
        emptyValue: null
      }
    ]
  }

  get accountData() {
    return this.accounts.map(account => {
      return {
        id: account[0],
        name: account[1],
        category: account[2],
        parent: account[3] == null ? "null" : account[3]
      }
    });
  }
}

decorate(ChartOfAccountsStore, {
  accounts: observable,
  accountLookup: computed,
  parents: computed,
  columns: computed,
  accountData: computed,
  addAccount: action.bound,
  editAccount: action.bound,
  deleteAccount: action.bound,
  loadAccounts: action.bound
});

export default ChartOfAccountsStore;
