import React from "react";
import { observer } from "mobx-react";
import Color from 'color';

import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import ModalGenericStore from "../../../store/modalGenericStore";
import SelectAccount from "../selectAccount";
import ProductRegionAccountPickerViewStore from "./viewStore";

const modalStore = new ModalGenericStore();
const viewStore = new ProductRegionAccountPickerViewStore();

const useStyles = makeStyles(theme => ({
  modalBox: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    minWidth: 600,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  buttonRow: {
    marginTop: 25
  },
  tableLabel: {
    fontWeight: 500,
    backgroundColor: Color(theme.palette.info.light).alpha(0.25).string()
  }
}));


const ProductRegionAccountPicker = observer(props => {
  const classes = useStyles();
  const {
    value,
    simData,
    saveValue,
    accounts,
    store
  } = props;

  const close = () => {
    viewStore.reset();
    modalStore.close();
  }

  const save = () => {
    saveValue(viewStore.values);
    viewStore.reset();
    close();
  }

  const initialIsMulti = Array.isArray(value);
  viewStore.initialize(store, simData, initialIsMulti);
  viewStore.setValues(value);
  const {
    filteredProducts,
    filteredRegions
  } = viewStore;

  return (
    <Box display="flex" flexDirection="column">
      <FormControl component="fieldset">
        <RadioGroup aria-label="account choices" 
          name="account-choices" 
          value={viewStore.isMultipleAccounts} 
          onChange={e => {viewStore.setMultipleAccounts(e.target.value)}}>
          <FormControlLabel value={false} control={<Radio />} label="Single Account" />
          <FormControlLabel value={true} control={<Radio />} label="Separate per product/region" />
        </RadioGroup>
      </FormControl>
      {!viewStore.isMultipleAccounts && 
        <SelectAccount
          value={value} 
          accounts={accounts} 
          onChange={saveValue} />}
      {viewStore.isMultipleAccounts && <Box>
        <Button
          id="btn-product-region-chart-picker"
          onClick={modalStore.open}
          color="secondary"
          variant="contained">Select Accounts</Button>
        <Modal open={modalStore.isOpen} onClose={modalStore.close}>
          <Box
            className={classes.modalBox}
            display="flex"
            flexDirection="column">
            <TableContainer>
              <Table id="tbl-select-accounts">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableLabel}></TableCell>
                    {filteredRegions.map(region => (
                      <TableCell key={region.value} className={classes.tableLabel}>{region.name}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredProducts.map((product, productIndex) => (
                    <TableRow key={product.value}>
                      <TableCell component="th" scope="row" className={classes.tableLabel}>{product.name}</TableCell>
                      {filteredRegions.map((region, regionIndex) => (
                        <TableCell key={`${product.value}-${region.value}`}>
                          <SelectAccount
                            value={viewStore.values[productIndex][regionIndex]}
                            accounts={accounts}
                            onChange={(value) => { viewStore.setValue(productIndex, regionIndex, value) }}
                            style={{ minWidth: 100 }} />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              className={classes.buttonRow}
              display="flex"
              justifyContent="space-between"
            >
              <Button
                id="btn-save-accounts"
                onClick={save}
                color="primary"
                variant="contained">
                Save
            </Button>
              <Button
                id="btn-close-variable-picker"
                onClick={close}
                color="secondary"
                variant="contained"
              >
                Close
            </Button>
            </Box>
          </Box>
        </Modal>
      </Box>}
    </Box>
  );

});

export default ProductRegionAccountPicker;
