import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Link from "@material-ui/core/Link";
import ListItemText from "@material-ui/core/ListItemText";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  drawer: {
    flexShrink: 0,
    width: drawerWidth
  },
  drawerPaper: {
    top: 40,
    width: drawerWidth,
    height: "calc(100% - 40px)"
  }
}));

function TableOfContents(props) {
  const {
    moduleContent,
    width
  } = props;

  const classes = useStyles(width);

  return (
    <>
      <Drawer
        id="table-of-contents"
        className={classes.drawer}
        classes={{ paper: classes.drawerPaper }}
        variant="permanent"
        anchor="left"
      >
        <List>
          <ListItem key={"Table of Contents"}>
            <ListItemText primary={"Table of Contents"}></ListItemText>
          </ListItem>
          {moduleContent.map(item => (
            <ListItem button key={item.name}>
              <Link href={"#" + item.name}>
                <ListItemText primary={item.name}></ListItemText>
              </Link>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
}

export default TableOfContents;
