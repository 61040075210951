import { observable, action, decorate, computed } from "mobx";

import { getJsonModules } from "../../services/get_modules";
import { parseOutput as parseIO } from "../../services/moduleParsing";

function getIOValue(inputOutputObj) {
  if (inputOutputObj.value_type === "literal" && !isNaN(inputOutputObj.value)) {
    return Number(inputOutputObj.value);
  } else {
    return inputOutputObj.value;
  }
}

function isImportModule(module) {
  return module.type === "import-to-chart";
}

class ModuleEditorStore {
  availableModules = [];
  pipelineModules = [];
  selectedIndex = -1;
  varsVisible = false;
  editIO = false;
  createModule = false;
  newModuleType = "";
  isOpen = false;

  constructor(parent) {
    this.parent = parent;
    this.loadAvailableModules();
  }

  mapModule(module, index) {
    const isMulti =
      module.isMulti === null || module.isMulti === undefined
        ? true
        : module.isMulti;

    return {
      number: index + 1,
      type: module.type,
      friendlyType: this.friendlyModuleName(this.keyMap[module.type]),
      name: module.name,
      products: module.products || [],
      regions: module.regions || [],
      directInputs: module.directInputs || [],
      productRegionInputs: module.productRegionInputs || [],
      directOutputs: module.directOutputs || [],
      productRegionOutputs: module.productRegionOutputs || [],
      isMulti: isMulti,
      moduleRef: module
    };
  }

  get numberedPipeline() {
    return this.pipelineModules.map((module, index) => {
      return this.mapModule(module, index);
    });
  }

  get keyMap() {
    const mapping = {};
    this.availableModules.forEach(module => {
      mapping[module.jsonKey] = module.module_name;
    });

    return mapping;
  }

  get pipeline() {
    const moduleCount = this.numberedPipeline.length;

    return this.numberedPipeline.map(module => {
      return {
        name: `${module.number}. ${module.friendlyType}`,
        description: module.name,
        key: `${module.type}-${module.name}`,
        index: module.number - 1,
        canMoveUp: module.number > 1,
        canMoveDown: module.number < moduleCount,
        isSelected:
          this.selectedModule && this.selectedModule.number === module.number
      };
    });
  }

  get sortedAvailable() {
    const available = [...this.availableModules];

    return available.sort((first, second) => {
      if (first.module_name === second.module_name) {
        return 0;
      } else if (first.module_name < second.module_name) {
        return -1;
      } else {
        return 1;
      }
    });
  }

  get selectedModule() {
    if (this.selectedIndex > -1) {
      return this.mapModule(
        this.pipelineModules[this.selectedIndex],
        this.selectedIndex
      );
    }

    return null;
  }

  get directInputs() {
    return this.selectedModule
      ? this.selectedModule.directInputs.map(item => parseIO(item))
      : [];
  }

  get productRegionInputs() {
    return this.selectedModule
      ? this.selectedModule.productRegionInputs.map(item => parseIO(item))
      : [];
  }

  get directOutputs() {
    return this.selectedModule
      ? this.selectedModule.directOutputs.map(item => parseIO(item))
      : [];
  }

  get productRegionOutputs() {
    return this.selectedModule
      ? this.selectedModule.productRegionOutputs.map(item => parseIO(item))
      : [];
  }

  async loadAvailableModules() {
    const data = await getJsonModules();

    this.availableModules.clear();
    data.forEach(module => {
      module.friendlyName = this.friendlyModuleName(module.module_name);
      this.availableModules.push(module);
    });
  }

  loadPipelineModules(modules) {
    this.pipelineModules.clear();
    this.selectedIndex = -1;

    modules.forEach(module => {
      this.pipelineModules.push(module);
    });
  }

  selectModule(module) {
    this.selectedIndex = module.index;
  }

  showVars() {
    this.varsVisible = true;
  }

  hideVars() {
    this.varsVisible = false;
  }

  setEditIO() {
    this.editIO = !this.editIO;
  }

  addDirectInput(newDirectInput) {
    if (this.selectedModule) {
      const promise = new Promise((resolve, reject) => {
        const existing = this.selectedModule.directInputs.find(io => {
          return io.name === newDirectInput.name;
        });
        if (existing) {
          reject("Direct Input name already exists");
          return;
        }

        this.selectedModule.directInputs.push([
          newDirectInput.name,
          isImportModule(this.selectedModule) ? newDirectInput.value : getIOValue(newDirectInput),
          newDirectInput.value_type,
          newDirectInput.options
        ]);

        resolve();
      });

      return promise;
    }
  }

  friendlyModuleName(name) {
    if (name == null) {
      return "";
    } else {
      return name
        .match(/[A-Z][a-z]+|[0-9]+/g)
        .join(" ")
        .split("Module")[0];
    }
  }

  editDirectInputEntry(oldEntry, newEntry) {
    const promise = new Promise((resolve, reject) => {
      const index = oldEntry.tableData.id;
      
      if (index < 0) {
        reject();
        return;
      }

      const input = this.selectedModule.directInputs[index];
      input[0] = newEntry.name;
      input[1] = isImportModule(this.selectedModule) ? newEntry.value : getIOValue(newEntry);
      input[2] = newEntry.value_type;
      input[3] = newEntry.options;

      resolve();
    });

    return promise;
  }

  deleteDirectInputEntry(oldEntry) {
    const promise = new Promise((resolve, reject) => {
      const index = this.selectedModule.directInputs.findIndex(entry => {
        return entry[0] === oldEntry.name;
      });

      if (index < 0) {
        reject();
        return;
      }

      this.selectedModule.directInputs.splice(index, 1);
      resolve();
    });

    return promise;
  }

  addDirectOutput(newDirectOutput) {
    if (this.selectedModule) {
      const promise = new Promise((resolve, reject) => {
        this.selectedModule.directOutputs.push([
          newDirectOutput.name,
          getIOValue(newDirectOutput),
          newDirectOutput.value_type,
          newDirectOutput.options
        ]);

        resolve();
      });

      return promise;
    }
  }

  editDirectOutputEntry(oldEntry, newEntry) {
    const promise = new Promise((resolve, reject) => {
      const output = this.selectedModule.directOutputs[oldEntry.tableData.id];
      output[0] = newEntry.name;
      output[1] = getIOValue(newEntry);
      output[2] = newEntry.value_type;
      output[3] = newEntry.options;

      resolve();
    });

    return promise;
  }

  deleteDirectOutputEntry(oldEntry) {
    const promise = new Promise((resolve, reject) => {
      const index = oldEntry.tableData.id;

      this.selectedModule.directOutputs.splice(index, 1);
      resolve();
    });

    return promise;
  }

  addProductRegionInputs(newEntry) {
    if (this.selectedModule) {
      const promise = new Promise((resolve, reject) => {
        const existing = this.selectedModule.productRegionInputs.find(io => {
          return io.name === newEntry.name;
        });
        if (existing) {
          reject("Input name already exists");
          return;
        }

        this.selectedModule.productRegionInputs.push([
          newEntry.name,
          isImportModule(this.selectedModule) ? newEntry.value : getIOValue(newEntry),
          newEntry.value_type,
          newEntry.options
        ]);

        resolve();
      });

      return promise;
    }
  }

  editProductRegionInputsEntry(oldEntry, newEntry) {
    const promise = new Promise((resolve) => {
      const input = this.selectedModule.productRegionInputs[oldEntry.tableData.id];
      input[0] = newEntry.name;
      input[1] = isImportModule(this.selectedModule) ? newEntry.value : getIOValue(newEntry);
      input[2] = newEntry.value_type;
      input[3] = newEntry.options;

      resolve();
    });

    return promise;
  }

  deleteProductRegionInputsEntry(oldEntry) {
    const promise = new Promise((resolve) => {
      const index = oldEntry.tableData.id;

      this.selectedModule.productRegionInputs.splice(index, 1);
      resolve();
    });

    return promise;
  }

  addProductRegionOutputs(newDirectInput) {
    if (this.selectedModule) {
      const promise = new Promise((resolve) => {
        this.selectedModule.productRegionOutputs.push([
          newDirectInput.name,
          getIOValue(newDirectInput),
          newDirectInput.value_type,
          newDirectInput.options
        ]);

        resolve();
      });

      return promise;
    }
  }

  editProductRegionOutputsEntry(oldEntry, newEntry) {
    const promise = new Promise((resolve) => {
      const output = this.selectedModule.productRegionOutputs[oldEntry.tableData.id];
      output[0] = newEntry.name;
      output[1] = getIOValue(newEntry);
      output[2] = newEntry.value_type;
      output[3] = newEntry.options;
      resolve();
    });

    return promise;
  }

  deleteProductRegionOutputsEntry(oldEntry) {
    const promise = new Promise((resolve, reject) => {
      const index = oldEntry.tableData.id;
      
      if (index < 0) {
        reject();
        return;
      }

      this.selectedModule.productRegionOutputs.splice(index, 1);
      resolve();
    });

    return promise;
  }
  
  deleteModule(module) {
    if (this.selectedIndex === module.index) {
      this.selectedIndex = -1;
    } else if (this.selectedIndex > module.index) {
      this.selectedIndex -= 1;
    }

    this.pipelineModules.remove(this.pipelineModules[module.index]);
  }

  setIsOpen() {
    this.isOpen = !this.isOpen;
  }

  setIsMulti() {
    this.selectedModule.moduleRef.isMulti = !this.selectedModule.moduleRef
      .isMulti;
  }

  setModuleName(name) {
    this.selectedModule.moduleRef.name = name;
  }

  setModuleProducts(products) {
    if (products.length === 0) {
      delete this.selectedModule.moduleRef.products;
      return;
    }

    const sorted = [...products].sort();
    if (this.selectedModule.moduleRef.products === undefined) {
      this.selectedModule.moduleRef.products = []
    } else {
      this.selectedModule.moduleRef.products.clear();
    }
    sorted.forEach(product => {
      this.selectedModule.moduleRef.products.push(product);
    });
  }

  setModuleRegions(regions) {
    if (regions.length === 0) {
      delete this.selectedModule.moduleRef.regions;
      return;
    }

    const sorted = [...regions].sort();
    if (this.selectedModule.moduleRef.regions === undefined) {
      this.selectedModule.moduleRef.regions = []
    } else {
      this.selectedModule.moduleRef.regions.clear();
    }
    sorted.forEach(region => {
      this.selectedModule.moduleRef.regions.push(region);
    });
  }

  setNewModuleType(name) {
    this.newModuleType = name;
  }

  saveNewModule() {
    this.isOpen = !this.isOpen;
    const newModule = {
      type: this.newModuleType,
      name: "",
      isMulti: true,
      directInputs: [],
      productRegionInputs: [],
      directOutputs: [],
      productRegionOutputs: []
    };

    if (this.selectedIndex > -1) {
      this.selectedIndex = this.selectedIndex + 1;
      this.pipelineModules.splice(this.selectedIndex, 0, newModule);
    } else {
      this.selectedIndex = this.pipelineModules.length;
      this.pipelineModules.push(newModule);
    }
  }

  swapModules(index1, index2) {
    [
      this.pipelineModules[index1],
      this.pipelineModules[index2]
    ] = [
      this.pipelineModules[index2],
      this.pipelineModules[index1]
    ];

    if (this.selectedIndex === index1) { 
      this.selectedIndex = index2;
    } else if (this.selectedIndex === index2) {
      this.selectedIndex = index1;
    }
  }

  moveModuleUp(module) {
    const index = module.index;
    if (index < 1) {
      return;
    }

    this.swapModules(index, index - 1);
  }

  moveModuleDown(module) {
    const index = module.index;
    if (index > this.pipelineModules.length - 2) {
      return;
    }

    this.swapModules(index, index + 1);
  }
}

decorate(ModuleEditorStore, {
  availableModules: observable,
  pipelineModules: observable,
  varsVisible: observable,
  createModule: observable,
  newModuleType: observable,
  isOpen: observable,
  temporaryModule: observable,
  editIO: observable,
  selectedIndex: observable,
  numberedPipeline: computed,
  keyMap: computed,
  selectedModule: computed,
  pipeline: computed,
  sortedAvailable: computed,
  directInputs: computed,
  productRegionInputs: computed,
  directOutputs: computed,
  productRegionOutputs: computed,
  loadAvailableModules: action.bound,
  loadPipelineModules: action.bound,
  selectModule: action.bound,
  hideVars: action.bound,
  showVars: action.bound,
  saveTemporaryState: action.bound,
  setTempName: action.bound,
  setEditIO: action.bound,
  resetTemporaryState: action.bound,
  addDirectInput: action.bound,
  editDirectInputEntry: action.bound,
  deleteDirectInputEntry: action.bound,
  addDirectOutput: action.bound,
  editDirectOutputEntry: action.bound,
  deleteDirectOutputEntry: action.bound,
  addProductRegionInputs: action.bound,
  editProductRegionInputsEntry: action.bound,
  deleteProductRegionInputsEntry: action.bound,
  addProductRegionOutputs: action.bound,
  editProductRegionOutputsEntry: action.bound,
  deleteProductRegionOutputsEntry: action.bound,
  setTempIsMulti: action.bound,
  deleteModule: action.bound,
  setIsOpen: action.bound,
  setIsMulti: action.bound,
  setModuleName: action.bound,
  setModuleProducts: action.bound,
  setModuleRegions: action.bound,
  saveNewModule: action.bound,
  setNewModuleType: action.bound,
  swapModules: action.bound,
  moveModuleUp: action.bound,
  moveModuleDown: action.bound
});

export default ModuleEditorStore;
export const viewStore = new ModuleEditorStore();
