import React from "react";
import { observer } from "mobx-react";

import EntryEditorTable from "../../components/entryEditorTable";
import { Grid, TextField } from "@material-ui/core";


export default observer((props) => {
  const { viewStore } = props;
  
  return (
      <Grid
        container={true}
        spacing={3}
        direction="row"
        style={{ width: "100%" }}
      >
        <Grid item={true} xs={12}>
          <TextField 
            id="txt-round-number" 
            label="Number of rounds/timeslots"
            variant="outlined"
            type="number"
            value={viewStore.numberOfRounds}
            onChange={(event) => {viewStore.setRoundCount(event.target.value)}} />
        </Grid>
        <Grid item={true} xs={12} lg={6}>
          <EntryEditorTable viewStore={viewStore.productsStore} />
        </Grid>
        <Grid item={true} xs={12} lg={6}>
          <EntryEditorTable viewStore={viewStore.regionsStore} />
        </Grid>
      </Grid>
  );
});
