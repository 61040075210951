import React from "react";
import { observer } from "mobx-react";

import {
  TextField
} from "@material-ui/core";
import LiteralEditorViewStore from "./viewStore";

const viewStore = new LiteralEditorViewStore();

const LiteralEditor = observer(props => {
  const {
    value,
    onChange,
    key
  } = props;

  viewStore.setValue(value);

  const setValue = (event) => {
    viewStore.setValue(event.target.value);
    onChange(viewStore.value)
  }

  return (
    <TextField
      key={key}
      value={viewStore.displayValue}
      onChange={setValue} />
  );
});

export default LiteralEditor;