import React from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItem,
  Typography,
  Box
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const useStyles = makeStyles(theme => ({
  moduleList: {
    width: "100%",
    height: "calc(100vh - 325px)",
    overflowY: "auto"
  },
  moduleCard: {
    width: "100%"
  },
  title: {
    fontWeight: "bold"
  },
  selected: {
    backgroundColor: theme.palette.success.light,
    width: "100%"
  },
  item: {
    cursor: "pointer"
  },
  upArrow: {
    color: theme.palette.primary.dark
  },
  downArrow: {
    color: theme.palette.secondary.dark
  }
}));

export default observer(props => {
  const { 
    modules, 
    selectModule, 
    deleteModule, 
    moveModuleUp, 
    moveModuleDown 
  } = props;
  const classes = useStyles();

  const moveUp = (event, module) => {
    moveModuleUp(module);
    event.stopPropagation();
  };

  const moveDown = (event, module) => {
    moveModuleDown(module);
    event.stopPropagation();
  };

  return (
    <List 
      id="lst-module-pipeline"
      className={classes.moduleList}>
      {modules.map(module => {
        return (
          <ListItem
            key={module.key}
            className={classes.item}
            selected={module.isSelected}
            onClick={() => selectModule(module)}
          >
            <Card
              className={
                module.isSelected ? classes.selected : classes.moduleCard
              }
            >
              <CardHeader
                title={module.name}
                action={
                  <IconButton
                    aria-label="close"
                    onClick={(event) => {
                      deleteModule(module);
                      event.stopPropagation();
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                }
              ></CardHeader>
              <Box
                direction="row"
                display="flex"
                justifyContent="space-between"
              >
                <CardContent>
                  <Typography>{module.description}</Typography>
                </CardContent>
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{
                    marginTop: -25,
                    paddingRight: 2
                  }}
                >
                  <IconButton 
                    aria-label="up" 
                    className={classes.upArrow}
                    disabled={!module.canMoveUp}
                    onClick={(event) => moveUp(event, module)}>
                    <KeyboardArrowUpIcon fontSize="large" />
                  </IconButton>
                  <IconButton
                    aria-label="down"
                    disabled={!module.canMoveDown}
                    className={classes.downArrow}
                    onClick={(event) => moveDown(event, module)}
                  >
                    <KeyboardArrowDownIcon fontSize="large" />
                  </IconButton>
                </Box>
              </Box>
            </Card>
          </ListItem>
        );
      })}
    </List>
  );
});
