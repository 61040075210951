import React from "react";
import { TextField } from "@material-ui/core";
import { observer } from "mobx-react";
import VariablePicker from "./variablePicker";
import CurveEditor from "./curveEditor";
import ProductRegionAccountPicker from "../productRegionAccountPicker";
import CurveEditorViewStore from "./curveEditor/viewStore";
import SelectAccount from "../selectAccount";
import LiteralEditor from "./literalEditor"

function getStoreComponent(props, defaultNameComponent, defaultValueComponent) {
  const {
    accounts,
    isImport,
    isExport,
    isName
  } = props;

  if (isImport && isName) {
    return (<VariablePicker {...props} />);
  } else if (isExport && isName) {
    return (<VariablePicker {...props} />);
  } else if (isExport) {
    return defaultValueComponent;
  } else if (isImport) {
    return (<SelectAccount
      value={props.value}
      accounts={accounts}
      onChange={props.onChange} />);
  } else if (isName) {
    return defaultNameComponent;
  } else {
    return (<VariablePicker {...props} />);
  }
}

function getLiteralComponent(props, defaultNameComponent, defaultValueComponent, keyValue) {
  const {
    rowData,
    accounts,
    isImport,
    isName
  } = props;

  if (isImport && isName) {
    return defaultValueComponent;
  } else if (isImport) {
    return (<SelectAccount
      value={props.value}
      accounts={accounts}
      onChange={props.onChange} />);
  } else if (isName) {
    return defaultNameComponent;
  } else if (rowData.name && rowData.name.endsWith("_curve")) {
    const curveEditorViewStore = new CurveEditorViewStore();
    curveEditorViewStore.initialize(props.value);
    return (<CurveEditor key={keyValue} {...props} viewStore={curveEditorViewStore} />)
  } else {
    return defaultValueComponent;
  }
}

function getChartComponent(props, defaultNameComponent, defaultValueComponent, keyValue) {
  const {
    accounts,
    isExport,
    isName,
    isDirect
  } = props;

  if (isExport && isName && isDirect) {
    return (<SelectAccount
      value={props.value}
      accounts={accounts}
      onChange={props.onChange} />);
  } else if (isExport && isName) {
    return (<ProductRegionAccountPicker key={keyValue} {...props} />)
  } else if (isExport) {
    return defaultValueComponent;
  } else if (isName) {
    return defaultNameComponent;
  } else {
    if (isDirect) {
      return (<SelectAccount
        value={props.value}
        accounts={accounts}
        onChange={props.onChange} />);
    } else {
      return (<ProductRegionAccountPicker key={keyValue} {...props} />)
    }
  }
}

const InputValueEditor = observer(props => {
  const {
    isName,
    rowData,
    store
  } = props;

  const keyValue = `${store.selectedModule.number}-${props.id}-${isName ? 'name' : 'value'}`;

  const defaultNameComponent = (
    <TextField
      key={keyValue}
      value={props.value}
      onChange={e => { props.onChange(e.target.value) }} />);
  const defaultValueComponent = (
    <LiteralEditor
      key={keyValue}
      value={props.value}
      onChange={props.onChange} />
  )

  let component = null;
  switch (rowData.value_type) {
    case "store":
      component = getStoreComponent(props, defaultNameComponent, defaultValueComponent);
      break;
    case "chart":
      component = getChartComponent(props, defaultNameComponent, defaultValueComponent, keyValue)
      break;
    case "literal":
      return getLiteralComponent(props, defaultNameComponent, defaultValueComponent, keyValue);
    default:
      component = isName ? defaultNameComponent : defaultValueComponent;
      break;
  }

  return (
    <>
      {component}
    </>
  );
});

export default InputValueEditor;