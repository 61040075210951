import { observable, action, decorate, computed } from "mobx";
import { createTransformer } from "mobx-utils";

class SimInputsStore {
  selectedVariables = [];
  newVariable = "";

  addBlocked = createTransformer(variables => {
    const intersection = variables.filter(variable => (
      this.newVariables.includes(variable)
      ));

    return this.newVariable === "" || intersection.length > 0;
  });

  displayedVariables = createTransformer(variables => {
    return variables.map(variable => {
      return {
        name: variable,
        isSelected: this.selectedVariables.includes(variable)
      };
    });
  });

  allVariablesSelected = createTransformer(variables => {
    return variables.length === this.selectedVariables.length;
  });

  get deleteBlocked() {
    return this.selectedVariables.length === 0;
  }

  get newVariables() {
    if (!this.newVariable) {
      return [];
    }

    if (this.newVariable.includes(",")) {
      return this.newVariable.split(",").map(variable => {
        return variable.trim();
      }).filter(item => item !== "");
    } else if (this.newVariable.includes("\n")) {
      return this.newVariable.split("\n").map(variable => {
        return variable.trim();
      }).filter(item => item !== "");
    }

    return [this.newVariable];
  }

  get containsBulk() {
    return this.newVariable.includes("\n") || this.newVariable.length > 35;
  }

  toggleVariableSelection(variable) {
    const index = this.selectedVariables.indexOf(variable);
    if (index > -1) {
      this.selectedVariables.splice(index, 1);
    } else {
      this.selectedVariables.push(variable);
    }
  }

  selectVariables(variables) {
    this.selectedVariables.clear();
    variables.forEach(variable => { this.selectedVariables.push(variable); });
  }

  updateNewVariable (value) {
    this.newVariable = value;
  }

  deleteSelected() {
    this.selectedVariables.clear();
  }
}

decorate(SimInputsStore, {
  newVariable: observable,
  selectedVariables: observable,
  deleteBlocked: computed,
  newVariables: computed,
  containsBulk: computed,
  toggleVariableSelection: action.bound,
  updateNewVariable: action.bound,
  deleteSelected: action.bound,
  selectVariables: action.bound
});

export default SimInputsStore;