import { observable, action, decorate, computed } from "mobx";


class ProductRegionAccountPickerViewStore {
  allProducts = [];
  allRegions = [];
  moduleProducts = [];
  moduleRegions = [];
  values = [];
  isDirty = false;
  isMultipleAccounts = true;

  get filteredProducts() {
    return !!this.moduleProducts.length
      ? this.allProducts.filter(product => {
        return this.moduleProducts.find(item => product.value === item);
      })
      : this.allProducts
  }

  get filteredRegions() {
    return !!this.moduleRegions.length
      ? this.allRegions.filter(region => {
        return this.moduleRegions.find(item => region.value === item);
      })
      : this.allRegions
  }

  isAlreadyInitialized(store, simData) {
    let inputProducts = store.selectedModule.products;
    if (inputProducts.length !== this.moduleProducts.length) {
      return false;
    }
    for (let i = 0; i < this.moduleProducts.length; i++) {
      if (inputProducts[i] !== this.moduleProducts[i]) {
        return false;
      }
    }

    inputProducts = simData.products;
    if (inputProducts.length !== this.allProducts.length) {
      return false;
    }
    for (let i = 0; i < this.allProducts.length; i++) {
      if (inputProducts[i].name !== this.allProducts[i].name ||
        inputProducts[i].value !== this.allProducts[i].value) {
        return false;
      }
    }

    let inputRegions = store.selectedModule.regions;
    if (inputRegions.length !== this.moduleRegions.length) {
      return false;
    }
    for (let i = 0; i < this.moduleRegions.length; i++) {
      if (inputRegions[i] !== this.moduleRegions[i]) {
        return false;
      }
    }
    inputRegions = simData.regions;
    if (inputRegions.length !== this.allRegions.length) {
      return false;
    }
    for (let i = 0; i < this.allRegions.length; i++) {
      if (inputRegions[i].name !== this.allRegions[i].name ||
        inputRegions[i].value !== this.allRegions[i].value) {
        return false;
      }
    }

    return true;
  }

  initialize(store, simData, isMultipleAccounts) {
    if (this.isAlreadyInitialized(store, simData)) {
      return;
    }

    this.allProducts.clear();
    this.allRegions.clear();
    this.moduleProducts.clear();
    this.moduleRegions.clear();

    simData.products.forEach(product => {
      this.allProducts.push(product);
    });
    simData.regions.forEach(region => {
      this.allRegions.push(region);
    });

    store.selectedModule.products.forEach(product => {
      this.moduleProducts.push(product);
    });
    store.selectedModule.regions.forEach(region => {
      this.moduleRegions.push(region);
    });

    this.values = Array(this.filteredProducts.length).fill(
      Array(this.filteredRegions.length).fill("")
    );
    this.isMultipleAccounts = isMultipleAccounts;
  }

  areValuesValid(values) {
    if (!Array.isArray(values)) {
      return false;
    }
    if (values.length !== this.filteredProducts.length) {
      return false;
    }
    for (let i = 0; i < values.length; i++) {
      const value = values[i];
      if (!Array.isArray(value)) {
        return false;
      }
      if (value.length !== this.filteredRegions.length) {
        return false;
      }
    }

    return true;
  }

  setValues(values) {
    let effectiveValues = values;

    if (this.isDirty) {
      return;
    }
    if (!this.areValuesValid(values)) {
      effectiveValues = Array(this.filteredProducts.length).fill(
        Array(this.filteredRegions.length).fill("")
      );
    }
    if (JSON.stringify(effectiveValues) === JSON.stringify(this.values)) {
      return;
    }

    this.values = effectiveValues;
  }

  setValue(productIndex, regionIndex, value) {
    this.values[productIndex][regionIndex] = value;
    this.isDirty = true;
  }

  setMultipleAccounts(value) {
    const boolValue = value === "true";
    this.isMultipleAccounts = boolValue;
  }

  reset() {
    this.isDirty = false;
    this.allProducts = [];
    this.allRegions = [];
    this.moduleProducts = [];
    this.moduleRegions = [];
    this.values = [];
  }
}

decorate(ProductRegionAccountPickerViewStore, {
  allProducts: observable,
  allRegions: observable,
  moduleProducts: observable,
  moduleRegions: observable,
  values: observable,
  isDirty: observable,
  isMultipleAccounts: observable,
  filteredProducts: computed,
  filteredRegions: computed,
  initialize: action.bound,
  setValues: action.bound,
  setValue: action.bound,
  setMultipleAccounts: action.bound,
  reset: action.bound
});

export default ProductRegionAccountPickerViewStore;