import React from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  label: {
    fontWeight: 500,
    marginRight: 8
  }
}));

export default observer(props => {
  const { options } = props;

  const classes = useStyles();
  return (
    <>
      <Box flexDirection="column">
        {options != null &&
          options.default !== null &&
          options.default !== undefined && (
            <Box flexDirection="row">
              <Typography className={classes.label} component="span">
                Default:
              </Typography>
              <Typography component="span">{options.default}</Typography>
            </Box>
          )}
        {options != null &&
          options.magnitude !== null &&
          options.magnitude !== undefined && (
            <Box flexDirection="row">
              <Typography className={classes.label} component="span">
                Magnitude:
              </Typography>
              <Typography component="span">{options.magnitude}</Typography>
            </Box>
          )}
        {options != null &&
          options.precision !== null &&
          options.precision !== undefined && (
            <Box flexDirection="row">
              <Typography className={classes.label} component="span">
                Precision:
              </Typography>
              <Typography component="span">{options.precision}</Typography>
            </Box>
          )}
      </Box>
    </>
  );
});
