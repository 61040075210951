import React from "react";
import { observer } from "mobx-react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Modal,
  Typography,
  TextField
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import ModalGenericStore from "../../../../store/modalGenericStore";
import CurveDisplay from "./curveDisplay";

const modalStore = new ModalGenericStore();

const useStyles = makeStyles(theme => ({
  modalBox: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    width: 700,
    minHeight: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  inputField: {
    width: 80,
    marginRight: 8
  },
  heading: {
    marginBottom: 12
  },
  buttonRow: {
    marginTop: 25
  },
  coordinate: {
    textAlign: "right",
    width: 90
  },
  coordinateHeader: {
    textAlign: "center",
    fontWeight: 500,
    width: 90,
    display: "inline-block",
    borderBottom: "3px solid black",
    marginLeft: 16
  },
  chartButton: {
    "&:hover": {
      padding: 12,
      border: `2px solid ${theme.palette.success.light}`,
      opacity: 0.6
    }
  }
}));

const CurveEditor = observer(props => {
  const classes = useStyles();

  const {
    value,
    saveValue,
    viewStore
  } = props;

  viewStore.initialize(value);

  const close = () => {
    viewStore.reset();
    modalStore.close();
  }

  const save = () => {
    saveValue(viewStore.outputValue);
    close();
  }

  const performAdd = (event) => {
    if (viewStore.canAdd) {
      viewStore.addPoint();
    }

    event.preventDefault();
  }

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box onClick={modalStore.open} className={classes.chartButton}>
          <CurveDisplay
            dataJson={viewStore.displayCoordinatesJson}
            height={90}
            width={150}
            cursor="pointer"
            showGrid={false}
            showAxis={false} />
        </Box>
        <Modal open={modalStore.isOpen} onClose={close}>
          <Box

            className={classes.modalBox}
            display="flex"
            flexDirection="column"
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between">
              <Box display="flex" flexDirection="column">
                <Typography className={classes.heading} variant="h5">Data Points</Typography>
                <Box>
                  <form onSubmit={performAdd}>
                    <TextField
                      id="txt-x-value"
                      className={classes.inputField}
                      size="small"
                      label="X Value"
                      value={viewStore.inputX}
                      onChange={(event) => { viewStore.setInputX(event.target.value) }}
                      variant="outlined" />
                    <TextField
                      id="txt-y-value"
                      className={classes.inputField}
                      size="small"
                      label="Y Value"
                      value={viewStore.inputY}
                      onChange={(event) => { viewStore.setInputY(event.target.value) }}
                      variant="outlined" />
                    <Button
                      id="btn-add-data-point"
                      disabled={!viewStore.canAdd}
                      size="large"
                      color="primary"
                      variant="contained"
                      type="submit">Add</Button>
                  </form>
                </Box>
                <Box>
                  <List id="lst-data-points" dense={true}>
                    <ListItemText className={classes.coordinateHeader}>X</ListItemText>
                    <ListItemText className={classes.coordinateHeader}>Y</ListItemText>
                    {viewStore.displayCoordinates.map((point, index) => {
                      return (
                        <ListItem key={`${point.x}-${point.y}`}>
                          <ListItemText className={classes.coordinate}>{point.x}</ListItemText>
                          <ListItemText className={classes.coordinate}>{point.y}</ListItemText>
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => { viewStore.deletePoint(point.x) }}>
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </List>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" flexDirection="column">
                <Typography className={classes.heading} variant="h5">Visualization</Typography>
                <CurveDisplay dataJson={viewStore.displayCoordinatesJson} />
              </Box>
            </Box>
            <Box
              className={classes.buttonRow}
              display="flex"
              justifyContent="space-between">
              <Button
                id="btn-save-curve"
                // onClick={saveValue}
                color="primary"
                onClick={save}
                variant="contained">
                Save
              </Button>
              <Button
                id="btn-close-curve-editor"
                onClick={close}
                color="secondary"
                variant="contained"
              >
                Close
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
});

export default CurveEditor;