import { observable, action, decorate, computed } from "mobx";
import {
  getAllHelpPagesSubscription,
  savePageContent
} from "../../services/helpPageData";


class PageDataStore {
  pageData = [];
  currentPageKey = "";
  currentEditorContent = "";
  isEditing = false;
  isDirty = false;
  justSaved = false;

  get currentPage() {
    const currentPage = this.pageData.find(page => page.id === this.currentPageKey);

    return currentPage || { content: "", id: "" };
  }

  get sortedPages() {
    const pagesCopy = [...this.pageData];

    return pagesCopy.sort((first, second) => {
      if (first.id === "index") {
        return -1;
      } else if (second.id === "index") {
        return 1;
      } else if (first.name < second.name) {
        return -1;
      } else if (first.name > second.name) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  setCurrentPage(page) {
    this.currentPageKey = page;
  }

  updatePageData(pages) {
    this.pageData.clear();

    pages.forEach(page => {
      this.pageData.push(page);
    });
  }

  startEdit() {
    this.isEditing = true;
    this.isDirty = false;
    this.currentEditorContent = this.currentPage.content;
  }

  stopEdit() {
    this.isEditing = false;
    this.currentEditorContent = "";
  }

  editContent(content) {
    this.currentEditorContent = content;
    this.isDirty = true;
  }

  async saveEditorContent() {
    await savePageContent(this.currentPageKey, this.currentEditorContent);
    this.justSaved = true;
    this.isDirty = false;
  }

  expireSaved() {
    this.justSaved = false;
  }
}

decorate(PageDataStore, {
  pageData: observable,
  currentPageKey: observable,
  currentEditorContent: observable,
  isEditing: observable,
  justSaved: observable,
  isDirty: observable,
  currentPage: computed,
  sortedPages: computed,
  setCurrentPage: action.bound,
  updatePageData: action.bound,
  startEdit: action.bound,
  stopEdit: action.bound,
  editContent: action.bound,
  saveEditorContent: action.bound,
  expireSaved: action.bound
});

export const viewStore = new PageDataStore();

getAllHelpPagesSubscription(viewStore.updatePageData);

export default PageDataStore;
