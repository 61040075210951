import React from "react";
import { observer } from "mobx-react";
import { Typography } from "@material-ui/core";
import CurveDisplay from "./inputValueEditor/curveEditor/curveDisplay";
import CurveEditorViewStore from "./inputValueEditor/curveEditor/viewStore";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const ValueDisplay = observer(props => {
  const {
    rowData,
    value,
    accounts
  } = props;

  let displayValue = value;
  if (rowData.value_type.includes("chart")) {
    const account = accounts.find(item => item.number === value);
    displayValue = account ? account.name : value;
  }
  if (Array.isArray(displayValue) && rowData.value_type === "literal") {
    displayValue = JSON.stringify(displayValue);
  }
  let component = (<Typography>{displayValue}</Typography>);

  if (rowData.value_type === "literal" && String(rowData.name || "").endsWith("_curve")) {
    const curveStore = new CurveEditorViewStore();
    curveStore.initialize(value);
    component = (
      <CurveDisplay
        dataJson={curveStore.displayCoordinatesJson}
        height={90}
        width={150}
        showGrid={false}
        showAxis={false} />
    );
  }

  if (rowData.value_type.startsWith("chart")) {
    
    if (Array.isArray(rowData.value)) {
      component = (
        <Table>
          <TableBody>
            {rowData.value.map((prod, index) => {
              return (
                <TableRow key={prod.join()}>
                  {prod.map((value, reg) => {
                    return (
                      <TableCell key={`${index}-${reg}`} 
                      style={{padding: 1, border: "none"}}>{value}</TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )
    }
  }

  return (
    <>
      {component}
    </>
  );
});

export default ValueDisplay;