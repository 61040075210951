import React from "react";
import {
  Box,
  Button,
  IconButton,
  Paper,
  TextField,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddBoxIcon from '@material-ui/icons/AddBox';
import ViewStore from './store';

import { observer } from "mobx-react";

const viewStore = new ViewStore();
const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: 8
  },
  variable: {
    padding: "8px 16px",
    margin: 4,
    backgroundColor: theme.palette.success.light
  },
  selectedVariable: {
    padding: "8px 16px",
    margin: 4,
    backgroundColor: theme.palette.secondary.light
  },
  paper: {
    marginTop: 24,
    position: "relative"
  },
  addBox: {
    width: 350,
    position: "relative"
  },
  addBulk: {
    width: 350,
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 5,
    backgroundColor: "white"
  }
}));

const SimInputs = observer(props => {
  const {
    variables,
    addVariables,
    deleteVariables
  } = props;
  const classes = useStyles();

  const inputChange = (event) => {
    const value = event.target.value;
    viewStore.updateNewVariable(value);
  };

  const performAdd = (event) => {
    if (viewStore.addBlocked(variables)) {
      event.preventDefault();
      return;
    }

    addVariables(viewStore.newVariables);
    viewStore.newVariable = "";
    event.preventDefault();
  };

  const performDelete = () => {
    deleteVariables(viewStore.selectedVariables)
    viewStore.deleteSelected();
  }

  return (
    <>
      <Paper className={classes.wrapper}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4">Expected Inputs</Typography>
          <Box style={{position: "relative"}}>
            <form onSubmit={performAdd}>
              <TextField
                className={classes.addBox}
                onChange={inputChange}
                variant="outlined"
                value={viewStore.newVariable}
                placeholder="New Variable Name"></TextField>
              {viewStore.containsBulk && <TextField
                className={classes.addBulk}
                onChange={inputChange}
                variant="outlined"
                multiline 
                rowsMax="21"
                value={viewStore.newVariable}
                placeholder="New Variables (Separated by comma)"></TextField>}
              <IconButton
                disabled={viewStore.addBlocked(variables)}
                aria-label="Add Variable"
                type="submit"
                color="primary">
                <AddBoxIcon fontSize="large" />
              </IconButton>
            </form>
          </Box>
        </Box>
        {!viewStore.allVariablesSelected(variables) && <Button 
          onClick={() => viewStore.selectVariables(variables)}
          style={{marginRight: 12}}
          disabled={variables.length === 0}
          variant="contained" 
          color="primary">Select All</Button>}
        {!viewStore.deleteBlocked && <Button 
          onClick={() => viewStore.selectVariables([])}
          style={{marginRight: 12}}
          disabled={viewStore.deleteBlocked}
          variant="contained" 
          color="primary">Deselect All</Button>}
        <Button 
          onClick={performDelete}
          disabled={viewStore.deleteBlocked}
          variant="contained" 
          color="secondary">Delete Selected</Button>
        <Paper className={classes.paper} elevation={3}>
          <Box display="flex" flexWrap="wrap">
            {viewStore.displayedVariables(variables).map(variable => (
              <Paper 
                key={variable.name} 
                onClick={() => { viewStore.toggleVariableSelection(variable.name) }}
                className={variable.isSelected ? classes.selectedVariable : classes.variable}>
                <Typography>{variable.name}</Typography>
              </Paper>
            ))}
          </Box>
        </Paper>
      </Paper>
    </>
  );
});

export default SimInputs;
