import React from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  Paper,
  Typography,
  TextField,
  Checkbox
} from "@material-ui/core";
import InputOutputDisplay, { INPUT_EDITOR, OUTPUT_EDITOR, INPUT_IMPORT_EDITOR, INPUT_EXPORT_EDITOR } from "./ioDisplay";
import ProductRegionFilter from "./productRegionFilter";

const useStyles = makeStyles(theme => ({
  label: {
    fontWeight: "bold"
  },
  sectionLabel: {
    fontWeight: "bold",
    marginTop: 16
  },
  root: {
    padding: 8,
    height: "calc(100vh - 325px)",
    overflowY: "auto"
  }
}));

const getInputEditorType = selectedModule => {
  if (selectedModule && selectedModule.type === "import-to-chart") {
    return INPUT_IMPORT_EDITOR;
  } else if (selectedModule && selectedModule.type === "export") {
    return INPUT_EXPORT_EDITOR;
  } else {
    return INPUT_EDITOR;
  }
}

export default observer(props => {
  const {
    store,
    currentVariables,
    simData,
    accounts,
    products,
    regions
  } = props;
  const classes = useStyles();

  const inputEditorType = getInputEditorType(store.selectedModule);
  if (store.selectedModule !== null) {
    return (
      <>
        <Paper elevation={0} className={classes.root}>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={3}>
              <Typography component="strong" className={classes.label}>
                Type:
              </Typography>
            </Grid>
            <Grid item={true} xs={9}>
              <Typography>{store.selectedModule.friendlyType}</Typography>
            </Grid>
            <Grid item={true} xs={3}>
              <Typography component="strong" className={classes.label}>
                Description:
              </Typography>
            </Grid>
            <Grid item={true} xs={9}>
              <TextField
                fullWidth
                value={store.selectedModule.name}
                onChange={(event) => { store.setModuleName(event.target.value) }}
              ></TextField>
            </Grid>

            <Grid item={true} xs={3}>
              <Typography component="strong" className={classes.label}>
                Product Filter:
              </Typography>
            </Grid>
            <Grid item={true} xs={9}>
              <ProductRegionFilter
                saveItems={store.setModuleProducts}
                label="Products"
                availableItems={products}
                selectedItems={store.selectedModule.products} />
            </Grid>
            <Grid item={true} xs={3}>
              <Typography component="strong" className={classes.label}>
                Region Filter:
              </Typography>
            </Grid>
            <Grid item={true} xs={9}>
              <ProductRegionFilter
                saveItems={store.setModuleRegions}
                label="Regions"
                availableItems={regions}
                selectedItems={store.selectedModule.regions} />
            </Grid>

            <Grid item={true} xs={3}>
              <Typography component="strong" className={classes.label}>
                isMulti
              </Typography>
              <Checkbox
                checked={store.selectedModule.isMulti}
                onChange={store.setIsMulti}
                value="primary"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </Grid>
          </Grid>
          <InputOutputDisplay
            title="Direct Inputs"
            id="tbl-direct-inputs"
            inputOutputList={store.directInputs}
            inputVariables={currentVariables}
            addEntry={store.addDirectInput}
            editEntry={store.editDirectInputEntry}
            deleteEntry={store.deleteDirectInputEntry}
            editorType={inputEditorType}
            simData={simData}
            accounts={accounts}
            store={store}
            isDirect={true}
            key={`tbl-direct-inputs-${store.selectedModule.number}`}
          />
          <InputOutputDisplay
            title="Product/Region Inputs"
            id="tbl-prod-reg-inputs"
            inputVariables={currentVariables}
            inputOutputList={store.productRegionInputs}
            addEntry={store.addProductRegionInputs}
            editEntry={store.editProductRegionInputsEntry}
            deleteEntry={store.deleteProductRegionInputsEntry}
            editorType={inputEditorType}
            simData={simData}
            accounts={accounts}
            store={store}
            isDirect={false}
            key={`tbl-prod-reg-inputs-${store.selectedModule.number}`}
          />
          <InputOutputDisplay
            title="Direct Outputs"
            id="tbl-direct-outputs"
            inputOutputList={store.directOutputs}
            addEntry={store.addDirectOutput}
            editEntry={store.editDirectOutputEntry}
            deleteEntry={store.deleteDirectOutputEntry}
            editorType={OUTPUT_EDITOR}
            simData={simData}
            accounts={accounts}
            store={store}
            isDirect={true}
            key={`tbl-direct-outputs-${store.selectedModule.number}`}
          />
          <InputOutputDisplay
            title="Product/Region Outputs"
            id="tbl-prod-reg-outputs"
            inputOutputList={store.productRegionOutputs}
            addEntry={store.addProductRegionOutputs}
            editEntry={store.editProductRegionOutputsEntry}
            deleteEntry={store.deleteProductRegionOutputsEntry}
            editorType={OUTPUT_EDITOR}
            simData={simData}
            accounts={accounts}
            store={store}
            isDirect={false}
            key={`tbl-prod-reg-outputs-${store.selectedModule.number}`}
          />
        </Paper>
      </>
    );
  } else {
    return (
      <>
        <Typography>None</Typography>
      </>
    );
  }
});
