import React from "react";
import { observer } from "mobx-react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Modal,
  Typography,
  Select
} from "@material-ui/core";
import ModalGenericStore from "../../../../store/modalGenericStore";
import VariablePickerViewStore from "./viewStore";

const modalStore = new ModalGenericStore();
const viewStore = new VariablePickerViewStore();

const useStyles = makeStyles(theme => ({
  modalBox: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  variable: {
    color: theme.palette.success.dark,
    fontWeight: 500,
    marginTop: 25
  },
  buttonRow: {
    marginTop: 25
  },
  templateSelect: {
    minWidth: 150
  }
}));


const VariablePicker = observer(props => {
  const classes = useStyles();
  const {
    value,
    availableVariables,
    simData,
    saveValue
  } = props;

  const {
    products,
    regions,
    numberOfRounds
  } = simData;

  viewStore.setValue("startingValue", value);

  const setValue = (event) => {
    viewStore.setValue(event.target.name, event.target.value);
  }

  const close = () => {
    viewStore.reset();
    modalStore.close();
  }

  const save = () => {
    saveValue(viewStore.outputValue);
    close();
  }

  const roundData = viewStore.roundList(numberOfRounds);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography id="lbl-variable-picker-value">{value}</Typography>
      <Button
        id="btn-variable-picker"
        onClick={modalStore.open}
        color="secondary"
        variant="contained">Variable Picker</Button>
      <Modal open={modalStore.isOpen} onClose={modalStore.close}>
        <Box
          className={classes.modalBox}
          display="flex"
          flexDirection="column">
          <FormControl id="ddl-variable-picker-select-variable">
            <InputLabel id="lbl-variable-picker-select-variable">Select Variable</InputLabel>
            <Select
              value={viewStore.variable}
              name="variable"
              onChange={setValue}
              labelId="lbl-variable-picker-select-variable">
              {availableVariables.map(variable => {
                return (
                  <MenuItem
                    key={variable.name}
                    value={variable.name}>{variable.name}</MenuItem>);
              })}
            </Select>
          </FormControl>
          <Box
            style={{ marginTop: 20 }}
            display="flex"
            justifyContent="space-between">
            <FormControl
              id="ddl-variable-picker-product"
              className={classes.templateSelect}
              disabled={!viewStore.hasProductTemplate}>
              <Select
                value={viewStore.productSelection}
                name="product"
                onChange={setValue}
                displayEmpty>
                <MenuItem value="">Template</MenuItem>
                {products.map(prod => {
                  return (
                    <MenuItem
                      key={prod.value}
                      value={prod.value}>{prod.name}</MenuItem>);
                })}
              </Select>
              <FormHelperText>Product</FormHelperText>
            </FormControl>
            <FormControl
              id="ddl-variable-picker-region"
              className={classes.templateSelect}
              disabled={!viewStore.hasRegionTemplate}>
              <Select
                value={viewStore.regionSelection}
                name="region"
                onChange={setValue}
                displayEmpty>
                <MenuItem value="">Template</MenuItem>
                {regions.map(reg => {
                  return (
                    <MenuItem key={reg.value} value={reg.value}>{reg.name}</MenuItem>);
                })}
              </Select>
              <FormHelperText>Region</FormHelperText>
            </FormControl>
            <FormControl
              id="ddl-variable-picker-round"
              className={classes.templateSelect}
              disabled={!viewStore.hasRoundTemplate}>
              <Select
                value={viewStore.roundSelection}
                name="round"
                onChange={setValue}
                displayEmpty>
                <MenuItem value="">Template</MenuItem>
                {roundData.map(round => {
                  return (<MenuItem key={round} value={round}>{round}</MenuItem>);
                })}
              </Select>
              <FormHelperText>Round</FormHelperText>
            </FormControl>
          </Box>
          <Typography
            id="lbl-variable-picker-output"
            className={classes.variable}>{viewStore.outputValue}</Typography>
          <Box
            className={classes.buttonRow}
            display="flex"
            justifyContent="space-between"
          >
            <Button
              id="btn-save-variable"
              onClick={save}
              color="primary"
              variant="contained">
              Save
            </Button>
            <Button
              id="btn-close-variable-picker"
              onClick={close}
              color="secondary"
              variant="contained"
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
});

export default VariablePicker;