import React from "react";
import ReactMarkdown from "react-markdown";

import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";

import "./font.css";

const useStyles = makeStyles(theme => ({
  root: {
    width: "98%",
    margin: 8
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  }
}));

function DescriptionCard({ title, content }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root}>
      <CardHeader title={title} 
        titleTypographyProps={{variant: 'h2'}}
        style={{ width: "95%" }} 
        action={
        <CardActions disableSpacing>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
      }></CardHeader>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent style={{ width: "95%" }}>
          <ReactMarkdown class="card_text">{content}</ReactMarkdown>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default DescriptionCard;
