import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Box, TextField } from "@material-ui/core";

const processValue = value => {
  if (value === "") {
    return undefined;
  }

  return isNaN(value) ? value : Number(value);
};

const importValue = value => {
  if (value === undefined) {
    return "";
  }

  return value;
};

export default observer(props => {
  const { options, save } = props;

  const [defaultValue, setDefault] = useState(
    importValue(options ? options.default : "")
  );
  const [magnitude, setMagnitude] = useState(
    importValue(options ? options.magnitude : "")
  );
  const [precision, setPrecision] = useState(
    importValue(options ? options.precision : "")
  );
  const [isDirty, setIsDirty] = useState(false);

  const onValueChange = (event, method) => {
    method(event.target.value);
    setIsDirty(true);
  };

  useEffect(() => {
    if (isDirty) {
      save({
        default: processValue(defaultValue),
        magnitude: processValue(magnitude),
        precision: processValue(precision)
      });
      setIsDirty(false);
    }
  }, [isDirty, save, defaultValue, magnitude, precision]);

  return (
    <>
      <Box flexDirection="column">
        <TextField
          value={defaultValue}
          label="Default Value"
          onChange={event => onValueChange(event, setDefault)}
        />
        <TextField
          value={magnitude}
          label="Magnitude"
          onChange={event => onValueChange(event, setMagnitude)}
        />
        <TextField
          value={precision}
          label="Precision"
          onChange={event => onValueChange(event, setPrecision)}
        />
      </Box>
    </>
  );
});
