import firebase from "./firebase";

const db = firebase.firestore();
const pagesCollection = db.collection("helpPagesPublished");

export function getHelpPageSubscription(pageKey, callback) {
  pagesCollection
    .doc(pageKey)
    .onSnapshot((document) => {
      callback(document.data());
    });
}

export function getAllHelpPagesSubscription(callback) {
  db.collection("helpPagesPublished")
    .onSnapshot((documents) => {
      const results = [];
      documents.forEach(doc => {
        const result = {
          id: doc.id
        }
        results.push(Object.assign(result, doc.data()));
      });

      callback(results);
    });
}

export function savePageContent(pageKey, content) {
  return pagesCollection.doc(pageKey).set({content}, {merge: true});
}

export async function saveNewPage(pageKey, name) {
  const page = await pagesCollection.doc(pageKey).get();

  if (page.exists) {
    throw new Error("A page with that name already exists");
  }

  return pagesCollection.doc(pageKey).set({
    name,
    content: `# ${name}`
  });
}