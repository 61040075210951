import React from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";
import { 
  Box, 
  Button, 
  FormControl, 
  MenuItem, 
  Select, 
  Toolbar } from "@material-ui/core";

import UndoIcon from '@material-ui/icons/Undo';
import RedoIcon from '@material-ui/icons/Redo';
import SaveButtons from "../saveButtons";
import JsonWindow from "../jsonWindow";
import JsonImporter from "../jsonImporter";
import ConfirmDialog from "../confirmDialog";

const useStyles = makeStyles(theme => ({
  button: {
    marginRight: theme.spacing(1)
  },
  toolbar: {
    position: "fixed",
    width: "calc(100% - 48px)",
    top: 48,
    left: 0,
    zIndex: 15,
    backgroundColor: "#eee"
  },
  sectionSelection: {
    minWidth: 220
  }
}));

export default observer((props) => {
  const classes = useStyles();
  const {
    viewStore
  } = props;

  return (
    <Toolbar className={classes.toolbar}>
      <Box
        display="flex"
        flexWrap="wrap"
        style={{ width: "100%" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <FormControl className={classes.sectionSelection}>
          {/* <InputLabel id="model-edit-section">Section</InputLabel> */}
          <Select
            id="ddl-module-section"
            labelId="model-edit-section"
            value={viewStore.activeSection}
            onChange={(event) => viewStore.setActiveSection(event.target.value)}
            variant="outlined">
            <MenuItem value="core">Core Info</MenuItem>
            <MenuItem value="initial">Initial Values</MenuItem>
            <MenuItem value="chart">Chart of Accounts</MenuItem>
            <MenuItem value="inputs">Inputs from Simulation</MenuItem>
            <MenuItem value="modules">Modules</MenuItem>
          </Select>
        </FormControl>
        <Box>
          <Button
            id="btn-undo"
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={viewStore.undo}
            startIcon={<UndoIcon />}
            disabled={!viewStore.isUndoAvailable}>Undo</Button>
          <Button
            id="btn-redo"
            variant="contained"
            color="secondary"
            onClick={viewStore.redo}
            endIcon={<RedoIcon />}
            disabled={!viewStore.isRedoAvailable}>Redo</Button>
        </Box>
        <Box
          display="flex"
          style={{ width: 450 }}
          justifyContent="space-between"
          alignItems="center"
        >
          <JsonImporter viewStore={viewStore} />
          <JsonWindow
            handleCopy={() => viewStore.copy_json()}
            json={viewStore.json}
          ></JsonWindow>
          <SaveButtons
            clear={() => {
              viewStore.clear();
            }}
            save={() => {
              viewStore.save();
            }}
          ></SaveButtons>
          <ConfirmDialog
            confirmAction={viewStore.clear}
            buttonLabel="Clear"
            title="Clear Model?"
            text="Are you sure you want to clear your Model? This action cannot be undone."
          ></ConfirmDialog>
        </Box>
      </Box>
    </Toolbar>
  );
});
