import React from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Typography,
  Select,
  MenuItem,
  Modal,
  Box,
  Button
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  label: {
    fontWeight: "bold"
  },
  sectionLabel: {
    fontWeight: "bold",
    marginTop: 16
  },
  root: {
    padding: 8
  },
  modalBox: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    width: "90%",
    maxWidth: 450,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  scroll: {
    overflow: "auto"
  },
  buttonRow: {
    marginTop: "20px"
  },
  importField: {
    width: "100%",
    maxWidth: 900
  }
}));

export default observer(props => {
  const { store } = props;
  const classes = useStyles();

  const onChange = event => {
    store.setNewModuleType(event.target.value);
  };

  return (
    <Modal class={classes.root} open={store.isOpen} onClose={store.setIsOpen}>
      <Box className={classes.modalBox} display="flex" flexDirection="column">
        <Typography variant="h6">Create Module</Typography>

        <Box>
          <Typography component="strong" className={classes.label}>
            Module Type:
          </Typography>
          <Select style={{ width: 250, marginLeft: 10 }} onChange={onChange}>
            {store.sortedAvailable.map(module => (
              <MenuItem key={module.module_name} value={module.jsonKey}>
                {module.friendlyName}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box
          className={classes.buttonRow}
          display="flex"
          justifyContent="space-between"
        >
          <Button color="primary" variant="contained" onClick={store.setIsOpen}>
            Cancel
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={store.saveNewModule}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
});
