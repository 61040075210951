import React from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Drawer,
  Grid,
  Paper,
  Typography,
  IconButton
} from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/AddBox";

import Pipeline from "./pipeline";
import VariableViewer from "./variableViewer";
import ModuleDetail from "./detail";
import ModuleCreate from "./create";

const useStyles = makeStyles(theme => ({
  root: {
    padding: 16,
    height: "calc(100vh - 180px)"
  },
  column: {
    height: "100%"
  },
  heading: {
    marginBottom: 20
  },
  variables: {
    padding: 16
  },
  buttonRow: {
    marginTop: "20px"
  }
}));

export default observer(props => {
  const classes = useStyles();
  const { 
    currentVariables,
    viewStore, 
    variables,
    simData,
    accounts,
    products,
    regions
  } = props;

  return (
    <>
      <Drawer
        open={viewStore.varsVisible}
        onClose={viewStore.hideVars}
        anchor="right"
      >
        <Box className={classes.variables}>
          <Typography variant="h6">Model Variables</Typography>
          <VariableViewer variables={variables} />
        </Box>
      </Drawer>
      <Paper className={classes.root} elevation={2}>
        <Box
          display="flex"
          style={{ width: "100%", marginRight: "40px" }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h4" className={classes.heading}>
            Module Pipeline
          </Typography>
          <Button
            color="primary"
            variant="contained"
            disabled={variables.length === 0}
            onClick={viewStore.showVars}
          >
            Show Variables
          </Button>
        </Box>
        <Grid
          container={true}
          spacing={3}
          direction="row"
          style={{ width: "100%" }}
        >
          <Grid item={true} lg={4} xs={12} className={classes.column}>
            <Typography variant="h6" className={classes.heading}>
              Pipeline
              <IconButton
                onClick={() => viewStore.setIsOpen()}
                aria-label="add"
              >
                <AddBoxIcon />
              </IconButton>
            </Typography>
            <Pipeline
              modules={viewStore.pipeline}
              selectModule={viewStore.selectModule}
              deleteModule={viewStore.deleteModule}
              moveModuleUp={viewStore.moveModuleUp}
              moveModuleDown={viewStore.moveModuleDown}
            />
          </Grid>
          <Grid item={true} lg={8} xs={12} className={classes.column}>
            <Typography variant="h6" className={classes.heading}>
              Module Detail
            </Typography>
            <ModuleDetail 
              store={viewStore} 
              currentVariables={currentVariables}
              accounts={accounts}
              simData={simData}
              products={products}
              regions={regions} />
            <ModuleCreate store={viewStore} />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
});
