import React from "react";
import { observer } from "mobx-react";
import Routes from "./routes";
import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from "./Theme"
import './App.scss'

const App = observer(() => {
  return (
    <MuiThemeProvider theme={theme}>
      <div className="App">
        <Routes />
      </div>
    </MuiThemeProvider>
  );
});
export default App;
