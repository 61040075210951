import { viewStore } from "../store/documentationStore";
var CONFIG = require("./config.json");

function buildComputeModel(type) {
  return {
    computeId: "ind_base_model",
    functionId: "return_description",
    version: "master",
    actors: [
      {
        id: "none",
        name: "none",
        roundNumber: 1,
        state: "none",
        inputs: {}
      }
    ],
    config: {
      type
    }
  };
}

function callApi(compute) {
  const request = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      isBatch: true,
      runInSequence: false,
      computeModels: [compute]
    }),
    
  };
  
  return fetch(CONFIG.apiUrl, request);
}

const get_module = async function () {
  const model = buildComputeModel("md");
  const response = await callApi(model);
  let responseJson = await response.json();

  viewStore.populateModules(responseJson[0]);
};

export const getJsonModules = async () => {
  const model = buildComputeModel("json");
  const response = await callApi(model);
  const json = await response.json();

  return json[0];
}

export default get_module;
