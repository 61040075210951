import React from "react";
import firebase from "../services/firebase";
import { observable, action, decorate, computed, reaction } from "mobx";

const db = firebase.firestore();

class SignInViewStore {
  user = null;

  constructor() {
    firebase.auth().onAuthStateChanged(user => {
      this.setUser(user);
    });
    this.load();
    reaction(
      () => this.user,
      () => {
        this.save();
      }
    );
  }

  load() {
    if (localStorage.getItem("FirebaseUser")) {
      this.user = JSON.parse(localStorage.getItem("FirebaseUser"));
    }
  }

  save() {
    localStorage.setItem("FirebaseUser", JSON.stringify(this.user));
  }

  get isLoggedIn() {
    if (
      this.user != null &&
      this.user.email != null &&
      this.user.email.includes("@regiscompany.com")
    ) {
      return true;
    } else {
      console.log("failed on this user");
    }
    return false;
  }

  get documentationUrl() {
    if (this.isLoggedIn) {
      return "/documentation";
    } else {
      return "/";
    }
  }

  get buildModelUrl() {
    if (this.isLoggedIn) {
      return "/build_model";
    } else {
      return "/";
    }
  }

  get userRoles() {
    if (!this.userData) {
      return [];
    }

    return this.userData.roles;
  }

  get canEditPages() {
    const roles = this.userRoles;

    return roles.includes("editor") || roles.includes("admin");
  }

  logout() {
    firebase.auth().signOut();
    this.user = null;
  }

  async setUser(user) {
    this.user = user;

    if (user !== null) {
      this.setUserData(user.uid);
    }
  }

  async setUserData(uid) {
    const userRef = db.collection("users").doc(uid);
    const userDoc = await userRef.get();

    if (userDoc.exists) {
      userRef.onSnapshot(user => {
        this.userData = user.data();
      });
    } else {
      this.createUserData()
    }
  }

  async createUserData() {
    const userData = {
      name: this.user.displayName,
      email: this.user.email,
      photoURL: this.user.photoURL,
      roles: ['viewer']
    }

    const usersCollection = db.collection("users")
    usersCollection.doc(this.user.uid).set(userData, { merge: false});
    usersCollection.doc(this.user.uid).onSnapshot(user => {
      this.userData = user.data();
    });
  }
}

decorate(SignInViewStore, {
  user: observable,
  userData: observable,
  isLoggedIn: computed,
  documentationUrl: computed,
  buildModelUrl: computed,
  userRoles: computed,
  setUser: action.bound,
  setUserData: action.bound,
  createUserData: action.bound,
  logout: action.bound
});

export default SignInViewStore;

export const viewStore = new SignInViewStore();
export const Context = React.createContext(SignInViewStore);
