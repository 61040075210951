import React from "react";
import { observer } from "mobx-react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Snackbar,
  Toolbar
} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import { MarkdownDisplay } from "./markdownDisplay";
import { viewStore } from "./pageDataStore";
import Contents from "./contents";
import { viewStore as userContext } from "../../store/signInViewStore";

import MarkdownIt from "markdown-it";
import MdEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import "./markdown-editor.css";

const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    alignSelf: "flex-end"
  },
  toolbar: {
    position: "fixed",
    width: "calc(100% - 288px)",
    top: 48,
    left: 240,
    zIndex: 15,
    backgroundColor: "#eee",
    height: 32
  },
  heightBuffer: {
    height: 32
  }
}));

export const HelpPage = observer((props) => {
  const currentPage = props.match.params.page || "default";
  const classes = useStyles();
  viewStore.setCurrentPage(currentPage);

  const content = viewStore.currentPage.content;
  const mdParser = new MarkdownIt();

  return (
    <Box display="flex">
      <Contents pages={viewStore.sortedPages} selectedPage={viewStore.currentPage.id} />
      <Box className={classes.content} id="documentation-detail-view">
        {userContext.canEditPages && (
          <Box>
            <Toolbar className={classes.toolbar}>
              <Box
                display="flex"
                style={{ width: "100%" }}
                // justifyContent="space-around"
                alignItems="center">
                <Button color="primary" onClick={viewStore.startEdit}>Edit</Button>
              </Box>
            </Toolbar>
            <Box className={classes.heightBuffer}></Box>
          </Box>
        )}
        {!viewStore.isEditing && <MarkdownDisplay content={content} />}
        {viewStore.isEditing && (<Box>
          <Button
            color="primary"
            disabled={!viewStore.isDirty}
            onClick={viewStore.saveEditorContent}>Save</Button>
          <Button
            color="secondary"
            onClick={viewStore.stopEdit}>Close Editor</Button>
          <MdEditor
            style={{minHeight: 600}}
            value={viewStore.currentEditorContent}
            renderHTML={(text) => mdParser.render(text)}
            onChange={({ text }) => viewStore.editContent(text)}
          />
        </Box>)}
      </Box>
      <Snackbar
        open={viewStore.justSaved}
        autoHideDuration={6000}
        onClose={viewStore.expireSaved}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity="success"
          onClose={viewStore.expireSaved}
        >Changes saved</MuiAlert>
      </Snackbar>
    </Box>
  );
});
