import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { HomeScreen } from "../pages/HomeScreen";
import { DocumentationScreen } from "../pages/DocumentationScreen";
import { BuildModelScreen } from "../pages/BuildModelScreen";
import { HelpPage } from "../pages/Help";
import NavBar from "../components/navbar";
import PrivateRoute from "./privateRoute";

function Routes(props) {
  return (
    <Router>
      <div>
        <nav>
          <NavBar></NavBar>
        </nav>
        <main style={{ marginTop: 40 }}>
          <Switch>
            <Route exact path="/" component={HomeScreen} />
            <PrivateRoute
              exact
              path={"/documentation"}
              component={DocumentationScreen}
            />
            <PrivateRoute
              exact
              path={"/build_model"}
              component={BuildModelScreen}
            />
            <PrivateRoute
              path={"/help/:page"}
              component={HelpPage}
            />
            <Route exact path={"/logout"} component={HomeScreen} />
            <Route path="*" component={() => "404 NOT FOUND"} />
          </Switch>
        </main>
      </div>
    </Router>
  );
}

export default Routes;
