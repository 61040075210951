import React from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";

import InitialValues from "../../components/initialValues";
import ChartOfAccounts from "../../components/chartOfAccounts";
import SimInputs from "../../components/simInputs";
import ModuleEditor from "../../components/moduleEditor";

import { Box } from "@material-ui/core";

import { viewStore } from "../../store/buildModelStore";
import ModelToolbar from "../../components/modelToolbar";
import CoreSection from "./coreSection";

const useStyles = makeStyles(theme => ({
  widget: {
    width: "95%"
  },
  content: {
    position: "relative",
    top: 75,
    marginBottom: 20
  }
}));

export const BuildModelScreen = observer(() => {
  const classes = useStyles();

  return (
    <Box className={classes.content}>
      <ModelToolbar viewStore={viewStore} />
      {viewStore.activeSection === "core" && <CoreSection viewStore={viewStore} />}
      {viewStore.activeSection === "initial" && (
        <InitialValues
          className={classes.widget}
          initialValuesStore={viewStore.initialValuesStore}
        ></InitialValues>
      )}
      {viewStore.activeSection === "chart" && (
        <ChartOfAccounts
          className={classes.widget}
          chartOfAccountsStore={viewStore.chartOfAccountsStore}
        />
      )}
      {viewStore.activeSection === "inputs" && (
        <SimInputs
          variables={viewStore.simInputs}
          addVariables={viewStore.addSimInputs}
          deleteVariables={viewStore.deleteSimInputs} />
      )}
      {viewStore.activeSection === "modules" && (
        <ModuleEditor
          viewStore={viewStore.moduleEditorStore}
          variables={viewStore.allVariables}
          accounts={viewStore.chartOfAccountsStore.accountLookup}
          currentVariables={viewStore.currentVariables}
          simData={viewStore.simData}
          products={viewStore.productsStore.valueData}
          regions={viewStore.regionsStore.valueData}
        />
      )}
    </Box>
  );
});
