import { observable, action, decorate, computed } from "mobx";

class DefinitionItemStore {
    entries = [];
    title = "";

    constructor(title) {
        this.title = title;
    }

    addEntry(newEntry) {
        const promise = new Promise((resolve, reject) => {
            const existing = this.entries.find(entry => {
                return entry === newEntry.name || entry.name === newEntry.name;
            });
            if (existing) {
                reject("Item already exists");
                return;
            }

            if (newEntry.condition) {
                this.entries.push({
                    name: newEntry.name,
                    condition: newEntry.condition
                });
            } else {
                this.entries.push(newEntry.name);
            }
            resolve();
        });

        return promise;
    }

    editEntry(oldEntry, newEntry) {
        const promise = new Promise((resolve, reject) => {
            const index = this.entries.findIndex(entry => {
                return entry === oldEntry.name || entry.name === oldEntry.name;
            });

            if (index < 0) {
                reject();
                return;
            }

            if (newEntry.condition) {
                this.entries[index] = {
                    name: newEntry.name,
                    condition: newEntry.condition
                };
            } else {
                this.entries[index] = newEntry.name;
            }
            resolve();
        });

        return promise;
    }

    deleteEntry(oldEntry) {
        const promise = new Promise((resolve, reject) => {
            const index = this.entries.findIndex(entry => {
                return entry === oldEntry.name || entry.name === oldEntry.name;
            });

            if (index < 0) {
                reject();
                return;
            }

            this.entries.splice(index, 1);
            resolve();
        });

        return promise;
    }

    loadEntries(entries) {
        this.entries.clear();

        entries.forEach(entry => {
            this.entries.push(entry);
        })
    }

    get columns() {
        return [
            { title: "Number", field: "number", editable: "never" },
            { title: "Name", field: "name" },
            { title: "Active Condition", field: "condition" }
        ]
    }

    get valueData() {
        return this.entries.map((entry, index) => {
            let name = "";
            let condition = "";

            if (typeof entry === "object") {
                name = entry.name;
                condition = entry.condition ? entry.condition : "";
            } else {
                name = entry;
            }

            return {
                number: index + 1,
                name,
                condition
            }
        });
    }
}

decorate(DefinitionItemStore, {
    entries: observable,
    title: observable,
    addEntry: action.bound,
    editEntry: action.bound,
    deleteEntry: action.bound,
    loadEntries: action.bound,
    columns: computed,
    valueData: computed
});

export default DefinitionItemStore;
