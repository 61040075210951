import React from "react";
import { observer } from "mobx-react";

import {
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  ListItemText
} from "@material-ui/core";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      minWidth: 250,
    },
  },
};

export default observer(props => {
  const {
    saveItems,
    label,
    availableItems,
    selectedItems
  } = props;

  const labelId = `lbl-module-${label.toLowerCase()}`;
  const selectId = `ddl-select-module-${label.toLowerCase()}`;
  const labelText = selectedItems.length > 0 
    ? label 
    : "No Filter (All in use)"

  return (
    <>
      <FormControl style={{ minWidth: 250 }}>
        <InputLabel id={labelId}>{labelText}</InputLabel>
        <Select
          labelId={labelId}
          id={selectId}
          value={selectedItems}
          renderValue={(selected) => (
            selected.map(item => {
              const currentItem = availableItems.find(value => value.number === item);
              return (
                <Chip
                  key={currentItem.number}
                  label={currentItem.name}
                  style={{ margin: 2 }} />
              );
            })
          )}
          onChange={(event) => saveItems(event.target.value)}
          multiple
          MenuProps={MenuProps}
        >
          {availableItems.map(filterItem => (
            <MenuItem key={filterItem.number} value={filterItem.number}>
              <Checkbox checked={!!selectedItems.find(item => item === filterItem.number)} />
              <ListItemText primary={filterItem.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
});
