import React from "react";
import { observer } from "mobx-react";
import { TextField } from "@material-ui/core";
import SelectAccount from "./selectAccount";
import ProductRegionAccountPicker from "./productRegionAccountPicker";

const OutputValueEditor = observer(props => {
  const {
    rowData,
    accounts,
    value,
    onChange,
    isDirect,
    store,
    isName
  } = props;

  const keyValue = `${store.selectedModule.number}-${props.id}-${isName ? 'name' : 'value'}`;

  const defaultComponent = (
    <TextField
      key={keyValue}
      value={value}
      onChange={e => { onChange(e.target.value) }} />);


  let component = null;
  if (isName) {
    component = defaultComponent;
  } else {
    switch (rowData.value_type) {
      case "chart":
      case "chart-debit":
      case "chart-credit":
        if (isDirect) {
          component = (<SelectAccount
            key={keyValue}
            value={props.value}
            accounts={accounts}
            onChange={onChange} />);
        } else {
          component = (<ProductRegionAccountPicker key={keyValue} {...props} />)
        }
  
        break;
      default:
        component = defaultComponent;
        break;
    }
  }
  

  return (
    <>
      {component}
    </>
  );
});

export default OutputValueEditor;