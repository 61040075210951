import React from "react";
import { observer } from "mobx-react";

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

const CurveDisplay = observer(props => {

  const {
    dataJson,
    height = 380,
    width = 400,
    showGrid = true,
    showAxis = true,
    cursor = "default"
  } = props;

  // Using JSON so the chart re-renders even when existing datapoints have their Y-value
  // updated.  Observing the array alone doesn't handle updates.
  const data = JSON.parse(dataJson);
  return (
    <>
      <LineChart
        cursor={cursor}
        width={width}
        height={height}
        data={data}>
        <XAxis hide={!showAxis} dataKey="x" />
        {showAxis && <YAxis />}
        {showGrid && <CartesianGrid strokeDasharray="3 3" />}
        <Tooltip />
        <Line type="monotone" dataKey="y" stroke="#82ca9d" />
      </LineChart>
    </>
  );
});

export default CurveDisplay;