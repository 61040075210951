import React from "react";
import { observer } from "mobx-react";

import MaterialTable from "material-table";
import OptionsCell from "./optionsCell";
import OptionsEditCell from "./optionsEditCell";
import InputValueEditor from "./inputValueEditor";
import OutputValueEditor from "./outputValueEditor";
import ValueDisplay from "./valueDisplay";

export const INPUT_EDITOR = "input";
export const INPUT_IMPORT_EDITOR = "input-import";
export const OUTPUT_EDITOR = "output";
export const INPUT_EXPORT_EDITOR = "output-export";

function getValueTypes(editorType) {
  if (editorType === INPUT_EDITOR || editorType === INPUT_EXPORT_EDITOR) {
    return {
      "store": "Store",
      "chart": "Chart",
      "literal": "Literal"
    };
  } else if (editorType === OUTPUT_EDITOR) {
    return {
      "store": "Store",
      "chart": "Chart",
      "chart-debit": "Chart - Debit",
      "chart-credit": "Chart - Credit"
    };
  } else {
    // INPUT_IMPORT_EDITOR
    return {
      "store": "Store",
      "literal": "Literal"
    }
  }
}

function getValueColumnDefinition(editorType, inputVariables, simData, parentProps) {
  const column = {
    title: "Value",
    field: "value"
  };

  if (editorType === INPUT_EDITOR) {
    column.editComponent = props => (
      <InputValueEditor
        {...parentProps}
        {...props}
        availableVariables={inputVariables}
        isImport={false}
        simData={simData}
        saveValue={props.onChange} />
    );
  } else if (editorType === INPUT_IMPORT_EDITOR) {
    column.editComponent = props => (
      <InputValueEditor
        {...parentProps}
        {...props}
        isImport={true}
        availableVariables={inputVariables}
        simData={simData}
        saveValue={props.onChange} />
    );
  } else if (editorType === OUTPUT_EDITOR) {
    column.editComponent = props => (
      <OutputValueEditor
        {...parentProps}
        {...props}
        availableVariables={inputVariables}
        simData={simData}
        isExport={false}
        saveValue={props.onChange} />
    );
  } else {
    // INPUT_EXPORT_EDITOR
    column.editComponent = props => (
      <InputValueEditor
        {...parentProps}
        {...props}
        isImport={false}
        isExport={true}
        availableVariables={inputVariables}
        simData={simData}
        saveValue={props.onChange} />
    );
  }

  column.render = rowData => (
    <ValueDisplay rowData={rowData} value={rowData.value} {...parentProps} />
  );

  return column;
}

function getNameColumnDefinition(editorType, inputVariables, simData, parentProps) {
  const column = {
    title: "Name",
    field: "name"
  };

  const isImport = editorType === INPUT_IMPORT_EDITOR;
  const isExport = editorType === INPUT_EXPORT_EDITOR;
  const isInput = editorType !== OUTPUT_EDITOR;

  if (isInput) {
    column.editComponent = props => (
      <InputValueEditor
        {...parentProps}
        {...props}
        isImport={isImport}
        isExport={isExport}
        isName={true}
        availableVariables={inputVariables}
        simData={simData}
        saveValue={props.onChange} />
    );
  } else {
    column.editComponent = props => (
      <OutputValueEditor
        {...parentProps}
        {...props}
        availableVariables={inputVariables}
        simData={simData}
        isExport={isExport}
        isName={true}
        saveValue={props.onChange} />
    );
  }

  return column;
}

export default observer(props => {
  const {
    id,
    inputVariables,
    inputOutputList,
    addEntry,
    editEntry,
    deleteEntry,
    title,
    editorType,
    simData
  } = props;

  const columns = [
    getNameColumnDefinition(editorType, inputVariables, simData, props),
    getValueColumnDefinition(editorType, inputVariables, simData, props),
    {
      title: "Value Type",
      field: "value_type",
      lookup: getValueTypes(editorType)
    },
    {
      title: "Options",
      field: "options",
      render: rowData => <OptionsCell options={rowData.options} />,
      editComponent: props => <OptionsEditCell options={props.value} save={props.onChange} />
    }
  ];

  const ioData = () => {
    return inputOutputList.map(io => {
      return {
        name: io.name,
        value: io.value,
        value_type: io.type,
        options: io.options
      };
    });
  };

  return (
    <div style={{ marginTop: 12 }} id={id}>
      <MaterialTable
        title={title}
        columns={columns}
        data={ioData()}
        editable={{
          onRowAdd: newData => addEntry(newData),
          onRowUpdate: (newData, oldData) => editEntry(oldData, newData),
          onRowDelete: oldData => deleteEntry(oldData)
        }}
        options={{
          paging: false,
          pageSizeOptions: []
        }}
      />
    </div>
  );
});