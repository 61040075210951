import { observable, action, decorate } from "mobx";

export default class ModalGenericStore {
  isOpen = false;

  open() {
    this.isOpen = true;
  }

  close() {
    this.isOpen = false;
  }
}

decorate(ModalGenericStore, {
  isOpen: observable,
  open: action.bound,
  close: action.bound
})