import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react";
import { Typography } from "@material-ui/core";

import Markdown from "../../components/markdown";
import TableOfContents from "../../components/tableOfContents";
import { viewStore } from "../../store/documentationStore";
import get_modules from "../../services/get_modules";
import LoadingOverlay from "../../components/loadingOverlay";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    alignSelf: "flex-end"
  }
}));

export const DocumentationScreen = observer(() => {
  const classes = useStyles();

  useEffect(() => {
    get_modules();
  }, []);

  return (
    <div className={classes.root}>
      <TableOfContents
        width={240}
        moduleContent={viewStore.moduleContent}
      ></TableOfContents>
      <main className={classes.content} id="documentation-detail-view">
        <Typography variant="h1">Module Documentation</Typography>
        <Markdown moduleContent={viewStore.moduleContent}></Markdown>
      </main>
      <LoadingOverlay
        message="Loading Documentation"
        isLoading={viewStore.isLoading}
      />
    </div>
  );
});
