import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal'
import { Box, CircularProgress, Typography } from '@material-ui/core';

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        border: "none",
        transform: `translate(-${top}%, -${left}%)`,
        color: "white",
        pointerEvents: "none",
        outline: "none"
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: "100%",
        backgroundColor: "transparent",
    },
    heading: {
        textShadow: "2px 2px #555"
    }
}));

export default function LoadingOverlay(props) {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);

    return (
        <Modal
            className={useStyles.box}
            open={props.isLoading}
        >
            <Box
                style={modalStyle}
                className={classes.paper}
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <CircularProgress color="primary" size={80} />
                <Typography variant="h2" className={classes.heading}>{props.message}</Typography>
            </Box>
        </Modal>
    );
}