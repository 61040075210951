import React from "react";
import MaterialTable from "material-table";

import {observer} from "mobx-react";

const EntryEditorTable = observer(props => {
  const viewStore = props.viewStore;

  return (
    <div>
      <div>
        <MaterialTable
          title={viewStore.title}
          columns={viewStore.columns}
          data={viewStore.valueData}
          editable={{
            onRowAdd: newData => viewStore.addEntry(newData),
            onRowUpdate: (newData, oldData) => viewStore.editEntry(oldData, newData),
            onRowDelete: oldData => viewStore.deleteEntry(oldData)
          }}
          options={{
            paging: false,
            minBodyHeight: 400,
            pageSizeOptions: []
          }}
          />
      </div>
    </div>
  );
});

export default EntryEditorTable;
