import React from "react";

import DescriptionCard from "../descriptionCard";
import GridListTile from "@material-ui/core/GridListTile";
import GridList from "@material-ui/core/GridList";
import { makeStyles } from "@material-ui/core/styles";

function Markdown({ moduleContent }) {
  const useStyles = makeStyles(theme => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden"
    },
    gridList: {
      flexWrap: "wrap"
    },
    tile: {
      width: "100%",
      paddingRight: 20,
      marginRight: 20
    },
    title: {
      color: theme.palette.primary.light
    },
    titleBar: {
      background:
        "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
    }
  }));

  const classes = useStyles();
  function returnMarkdown() {
    if (moduleContent != null) {
      const items = moduleContent.map(item => (
        <GridListTile
          cols={1}
          rows={1}
          id={item.name}
          className={classes.tile}
          style={{width: "100%", height: "inherit", padding: "inherit"}}
          key={item.name}>
          <DescriptionCard title={item.name} content={item.content} />
        </GridListTile>
      ));
      return items;
    } else {
      return null;
    }
  }

  return (
    <div className={classes.root}>
      <GridList cellHeight={200} spacing={1} className={classes.gridList}>
        {returnMarkdown()}
      </GridList>
    </div>
  );
}

export default Markdown;
