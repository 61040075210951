import { observable, action, decorate, computed } from "mobx";

class CurveEditorViewStore {
  coordinates = [];
  isInitialized = false;

  inputX = "";
  inputY = "";

  get inputCoordinate() {
    return {
      x: Number(this.inputX),
      y: Number(this.inputY)
    }
  }

  get outputValue() {
    return [
      this.displayCoordinates.map(point => point.x),
      this.displayCoordinates.map(point => point.y)
    ];
  }

  get canAdd() {
    return this.inputX !== "" 
      && this.inputY !== ""
      && !isNaN(this.inputCoordinate.x)
      && !isNaN(this.inputCoordinate.y);
  }

  get displayCoordinates() {
    const result = [...this.coordinates];

    return result.sort((lhs, rhs) => {
      return lhs.x - rhs.x;
    });
  }

  get displayCoordinatesJson() {
    return JSON.stringify(this.displayCoordinates);
  }

  initialize(valueArray) {
    if (this.isInitialized) {
      return;
    }

    this.coordinates = [];
    this.isInitialized = true;

    if (!valueArray || valueArray.length < 2) {
      return;
    }

    for (let i = 0; i < valueArray[0].length; i++) {
      this.coordinates.push({
        x: valueArray[0][i],
        y: valueArray[1][i]
      });
    }
  }

  addPoint() {
    const existing = this.coordinates.find(item => item.x === this.inputCoordinate.x);
    if (existing) {
      existing.y = Number(this.inputY);
    } else {
      this.coordinates.push(observable({
        x: Number(this.inputX),
        y: Number(this.inputY)
      }));
    }

    this.inputX = "";
    this.inputY = "";
  }

  deletePoint(xValue) {
    const index = this.coordinates.findIndex(item => item.x === xValue);
    this.coordinates.splice(index, 1);
  }

  setInputX(x) {
    this.inputX = x;
  }

  setInputY(y) {
    this.inputY = y;
  }

  reset() {
    this.isInitialized = false;
    this.coordinates = [];
    this.inputX = "";
    this.inputY = "";
  }
}

decorate(CurveEditorViewStore, {
  startingValue: observable,
  coordinates: observable,
  isInitialized: observable,
  inputX: observable,
  inputY: observable,
  inputCoordinate: computed,
  outputValue: computed,
  canAdd: computed,
  displayCoordinates: computed,
  displayCoordinatesJson: computed,
  initialize: action.bound,
  addPoint: action.bound,
  deletePoint: action.bound,
  setInputX: action.bound,
  setInputY: action.bound,
  reset: action.bound
});

export default CurveEditorViewStore;