import {observable, action, decorate, computed} from "mobx";

class InitialValuesStore {
  values = [];

  addInitialValue(name, value) {
    const promise = new Promise((resolve, reject) => {
      const realValue = isNaN(value) ? value : Number(value);

      const existing = this.values.find(item => item[0] === name);
      if (existing) {
        reject("Variable already exists");
        return;
      }
  
      this.values.push([name, realValue]);
      resolve();
    });
    
    return promise;
  }

  editInitialValue(originalName, name, value) {
    const promise = new Promise((resolve, reject) => {
      const existing = this.values.find(item => item[0] === originalName);
      if (!existing) {
        reject();
        return;
      }

      const realValue = isNaN(value) ? value : Number(value);
      existing[0] = name;
      existing[1] = realValue;
      resolve();
    });

    return promise;
  }

  deleteInitialValue(name) {
    const promise = new Promise((resolve, reject) => {
      const index = this.values.findIndex(item => item[0] === name);
      if (index < 0) {
        reject();
        return;
      }
    
      this.values.splice(index, 1);
      resolve();
    });

    return promise;
  }

  loadValues(values) {
    this.values.clear();

    values.forEach(value => {
      this.values.push(value);
    })
  }

  get columns() {
    return [
      { title: "Variable", field: "variable" },
      { title: "Value", field: "value" }
    ];
  }

  get valueData() {
    return this.values.map(initialValue => {
      return {
        variable: initialValue[0],
        value: initialValue[1]
      }
    });
  }

  get valueNames() {
    return this.values.map(value => {
      return value[0];
    })
  }

}

decorate(InitialValuesStore, {
  values: observable,
  addInitialValue: action.bound,
  loadValues: action.bound,
  columns: computed,
  valueData: computed,
  valueNames: computed,
  editInitialValue: action.bound,
  deleteInitialValue: action.bound
});

export default InitialValuesStore;
