import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyAzQnjRyd6M8WnT70IcR9XXKKeRYI-XMmQ",
  authDomain: "bdf-model-designer.firebaseapp.com",
  databaseURL: "https://bdf-model-designer.firebaseio.com",
  projectId: "bdf-model-designer",
  storageBucket: "bdf-model-designer.appspot.com",
  messagingSenderId: "270710596394",
  appId: "1:270710596394:web:95ea2e62db3a91c4dcb453",
  measurementId: "G-XMBG3HQZTX"
};

firebase.initializeApp(firebaseConfig);

export default firebase;
