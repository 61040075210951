import { observable, action, decorate, computed } from "mobx";

function isValueArray(value) {
  try {
    const json = JSON.parse(value);

    return Array.isArray(json);
  } catch (e) {
    return false;
  }
}

class LiteralEditorViewStore {
  value = null;

  get displayValue() {
    if (Array.isArray(this.value)) {
      return JSON.stringify(this.value);
    } 
    return this.value;
  }

  setValue(newValue) {
    if (newValue === this.value || newValue === undefined) {
      return;
    }

    if (newValue === "") {
      this.value = newValue;
    }
    else if (isValueArray(newValue)) {
      const value = JSON.parse(newValue)
      if (value !== this.value) {
        this.value = value;
      }
    } else if (isNaN(newValue)) {
      this.value = newValue;
    } else {
      this.value = Number(newValue);
    }
  }
}

decorate(LiteralEditorViewStore, {
  value: observable,
  displayValue: computed,
  setValue: action
});

export default LiteralEditorViewStore;