import React from "react";
import MaterialTable from "material-table";

import {observer} from "mobx-react";


const ChartOfAccounts = observer(props => {
  const viewStore = props.chartOfAccountsStore;

  return (
    <div>
      <div>
        <MaterialTable
          title="Chart of Accounts"
          columns={viewStore.columns}
          data={viewStore.accountData}
          editable={{
            onRowAdd: newData => viewStore.addAccount(newData),
            onRowUpdate: (newData, oldData) => viewStore.editAccount(oldData, newData),
            onRowDelete: oldData => viewStore.deleteAccount(oldData)
          }}
          options={{
            paging: false,
            pageSizeOptions: []
          }}
        />
      </div>
    </div>
  );
});

export default ChartOfAccounts;
