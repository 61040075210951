import { observable, action, decorate, computed, reaction, observe } from "mobx";

import ChartOfAccountsStore from "./chartOfAccountsStore";
import InitialValuesStore from "./initialValuesStore";
import DefinitionItemStore from "./definitionItemStore";
import ModuleEditorStore from "../components/moduleEditor/viewStore";
import { parseOutput } from "../services/moduleParsing";

const Diff = require("diff");
const UNDO_LENGTH = 25;


function addVariables(moduleOutputs, targetList, module) {
  moduleOutputs.forEach(output => {
    const parsed = parseOutput(output);
    if (parsed.type === "store") {
      targetList.push({
        name: parsed.value,
        source: `${module.number}. ${module.name}`
      });
    }
  });
}

class BuildModelStore {
  displayJson = false;
  jsonToImport = "";
  firstRun = false;
  chartOfAccountsStore = new ChartOfAccountsStore(this);
  initialValuesStore = new InitialValuesStore(this);
  productsStore = new DefinitionItemStore("Products");
  regionsStore = new DefinitionItemStore("Regions");
  moduleEditorStore = new ModuleEditorStore(this);
  history = [];
  redoStack = [];
  isLastChangeUndo = false;
  simInputs = [];
  activeSection = "core";
  numberOfRounds = 3;

  constructor() {
    this.load();
    reaction(
      () => this.json,
      () => {
        if (!this.firstRun) {
          this.save();
        }
        this.firstRun = false;
      }
    );

    observe(this, "json", change => {
      if (this.isLastChangeUndo) {
        this.isLastChangeUndo = false;
        return;
      }
      const newJson = change.newValue || "";
      const oldJson = change.oldValue || "";

      const patch = Diff.createPatch("model.json", oldJson, newJson, "old-json", "new-json");
      this.addToUndoHistory(patch);
    }, true);
  }

  addToUndoHistory(patch) {
    if (this.history.length >= UNDO_LENGTH) {
      this.history.shift();
    }
    this.history.push(patch)
  }

  load() {
    const saved = localStorage.getItem("BuildModelStore");
    if (saved) {
      this.jsonToImport = saved;
      this.importJson();
    }
  }

  save() {
    localStorage.setItem("BuildModelStore", this.json);
  }

  clear() {
    localStorage.setItem("BuildModelStore", "");
    this.jsonToImport = JSON.stringify({
      products: [],
      regions: [],
      initialValues: [],
      chartOfAccounts: [],
      modules: [],
      externalInputs: []
    });
    this.importJson();
  }

  generate_json() {
    this.displayJson = true;
  }

  copy_json() {
    navigator.clipboard.writeText(this.json);
  }

  get json() {
    var json_obj = {
      products: this.productsStore.entries,
      regions: this.regionsStore.entries,
      initialValues: this.initialValuesStore.values,
      chartOfAccounts: this.chartOfAccountsStore.accounts,
      modules: this.moduleEditorStore.pipelineModules,
      externalInputs: this.simInputs
    };

    return JSON.stringify(json_obj, null, 2);
  }

  get preModuleVariables() {
    const varList = [
      ...this.initialValuesStore.valueNames.map(value => {
        return { name: value, source: "Initial Values" }
      }),
      ...this.simInputs.map(value => {
        return { name: value, source: "Model Inputs" }
      })
    ];

    return varList;
  }

  get allVariables() {
    const varList = [...this.preModuleVariables];

    this.moduleEditorStore.numberedPipeline.forEach(module => {
      if (module.directOutputs) {
        addVariables(module.directOutputs, varList, module);
      }
      if (module.productRegionOutputs) {
        addVariables(module.productRegionOutputs, varList, module);
      }
    });

    return varList.sort();
  }

  get currentVariables() {
    const varList = [...this.preModuleVariables];

    for (var i = 0; i < this.moduleEditorStore.selectedIndex; i++) {
      const module = this.moduleEditorStore.numberedPipeline[i];
      if (module.directOutputs) {
        addVariables(module.directOutputs, varList, module);
      }
      if (module.productRegionOutputs) {
        addVariables(module.productRegionOutputs, varList, module);
      }
    }

    return varList.sort();
  }

  get isUndoAvailable() {
    return this.history.length > 0;
  }

  get isRedoAvailable() {
    return this.redoStack.length > 0;
  }

  get simData() {
    const products = this.productsStore.valueData.map(prod => {
      return {
        value: prod.number,
        name: `${prod.number} - ${prod.name}`
      }
    });
    const regions = this.regionsStore.valueData.map(reg => {
      return {
        value: reg.number,
        name: `${reg.number} - ${reg.name}`
      }
    });
    const rounds = [...Array(this.numberOfRounds + 1).keys()];

    return {
      products,
      regions,
      rounds,
      numberOfRounds: this.numberOfRounds
    }
  }

  importJson() {
    try {
      const imported = JSON.parse(this.jsonToImport);

      if (!imported) {
        return;
      }

      if (imported.products !== undefined) {
        this.productsStore.loadEntries(imported.products);
      }
      if (imported.regions !== undefined) {
        this.regionsStore.loadEntries(imported.regions);
      }
      if (imported.initialValues !== undefined) {
        this.initialValuesStore.loadValues(imported.initialValues);
      }
      if (imported.chartOfAccounts !== undefined) {
        this.chartOfAccountsStore.loadAccounts(imported.chartOfAccounts);
      }
      if (imported.modules !== undefined) {
        this.moduleEditorStore.loadPipelineModules(imported.modules);
      }
      if (imported.externalInputs !== undefined) {
        this.simInputs.clear();
        imported.externalInputs.forEach(input => {
          this.simInputs.push(input);
        });
      }
    } catch (err) {
      console.log(err);
      return;
    }
  }

  undo() {
    const patch = this.history.pop();
    const parsed = Diff.parsePatch(patch);

    parsed.forEach(item => {
      item.hunks.forEach(hunk => {
        hunk.lines.forEach((line, index) => {
          if (line.startsWith("-")) {
            hunk.lines[index] = line.replace("-", "+");
          } else if (line.startsWith("+")) {
            hunk.lines[index] = line.replace("+", "-");
          }
        })
      })
    });

    const updated = Diff.applyPatch(this.json, parsed);
    this.jsonToImport = updated;
    this.isLastChangeUndo = true;
    this.redoStack.push(patch);
    this.importJson();
  }

  redo() {
    const patch = this.redoStack.pop();

    const updated = Diff.applyPatch(this.json, patch);
    this.jsonToImport = updated;
    this.importJson();
  }

  addSimInputs(inputs) {
    inputs.forEach(input => { this.simInputs.push(input); })
  }

  deleteSimInputs(inputs) {
    inputs.forEach(input => {
      const index = this.simInputs.indexOf(input);
      if (index > -1) {
        this.simInputs.splice(index, 1);
      }
    });
  }

  setActiveSection(section) {
    this.activeSection = section;
  }

  setRoundCount(count) {
    this.numberOfRounds = Number(count);
  }
}

decorate(BuildModelStore, {
  displayJson: observable,
  jsonToImport: observable,
  history: observable,
  redoStack: observable,
  simInputs: observable,
  activeSection: observable,
  numberOfRounds: observable,
  json: computed,
  allVariables: computed,
  preModuleVariables: computed,
  currentVariables: computed,
  generate_json: action.bound,
  copy_json: action.bound,
  importJson: action.bound,
  clear: action.bound,
  undo: action.bound,
  redo: action.bound,
  load: action.bound,
  addSimInputs: action.bound,
  deleteSimInputs: action.bound,
  setActiveSection: action.bound,
  setRoundCount: action.bound
});

export default BuildModelStore;

export const viewStore = new BuildModelStore();
