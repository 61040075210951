import { observable, action, computed, decorate } from "mobx";
import ModalGenericStore from "../../../store/modalGenericStore";
import { saveNewPage } from "../../../services/helpPageData";

export default class HelpContentsViewStore {
  modal = new ModalGenericStore();
  title = "";
  showSuccess = false;
  showError = false;
  errorMessage = "";
  
  get pageKey() {
    return this.title
      .replace(/[^a-zA-Z0-9\- ]/g, "-")
      .replace(/ /g, "-")
      .toLowerCase();
  }

  setTitle(title) {
    this.title = title;
  }

  createNewPage() {
    this.title = "";
    this.modal.open();
  }

  async saveNewPage() {
    try {
      await saveNewPage(this.pageKey, this.title);
      this.modal.close();
      this.showSuccess = true;
    } catch (error) {
      this.errorMessage = `Save failed: ${error.message}`;
      this.showError = true;
    }
  }

  clearSuccess() {
    this.showSuccess = false;
  }

  clearError() {
    this.showError = false;
    this.errorMessage = "";
  }
}

decorate(HelpContentsViewStore, {
  title: observable,
  showSuccess: observable,
  showError: observable,
  errorMessage: observable,
  pageKey: computed,
  setTitle: action.bound,
  createNewPage: action.bound,
  saveNewPage: action.bound,
  clearSuccess: action.bound,
  clearError: action.bound
});