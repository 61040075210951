import React from "react";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { observer } from "mobx-react";

import ModalGenericStore from "../../store/modalGenericStore";

const modalStore = new ModalGenericStore();

const ConfirmDialog = observer((props) => {
  const {
    confirmAction,
    buttonLabel,
    title,
    text
  } = props;

  return (
    <div>
      <Button
        color="secondary"
        variant="contained"
        onClick={() => modalStore.open()}
      >
        {buttonLabel}
      </Button>
      <Dialog
        open={modalStore.isOpen}
        onClose={() => modalStore.close()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => modalStore.close()} color="primary">
            No
          </Button>
          <Button
            onClick={() => {
              confirmAction();
              modalStore.close();
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default ConfirmDialog;
