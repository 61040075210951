import { observable, action, decorate, computed } from "mobx";

class DocumentationStore {
  modules = [];

  populateModules(modules) {
    this.modules = modules;
  }

  get sortedModules() {
    return this.modules.slice().sort();
  }

  get moduleContent() {
    var moduleInfo = [];
    for (var i = 0; i < this.sortedModules.length; i++) {
      moduleInfo.push({
        name: this.sortedModules[i]
          .toString()
          .split("Module")[0]
          .split(" ")[1]
          .match(/[A-Z][a-z]+|[0-9]+/g)
          .join(" "),
        content: this.sortedModules[i].toString().split("Module")[1]
      });
    }
    return moduleInfo;
  }

  // isLoading is true until we have modules loaded into the array
  get isLoading() {
    return !this.modules.length;
  }
}

decorate(DocumentationStore, {
  modules: observable,
  isLoading: computed,
  populateModules: action,
  sortedModules: computed,
  moduleContent: computed
});

export default DocumentationStore;

export const viewStore = new DocumentationStore();
